import "./designable";

import {
    EFieldSettingsFieldDomain,
    EFieldSettingsFieldTypes,
    IFieldSettings,
    IPublicFilterCategory,
    TAdvFilterSelection,
    TDataProviderFilterSort,
} from "@components/dynamic/data-provider/types";
import AdvLoading, { EAdvLoadingMode } from "@components/other/loading";
import { EAdvSpinnerSize, TAdvSpinnerProps } from "@components/other/spinner";
import {
    EDataproviderClientOptions,
    useDataprovider,
} from "@data/dataprovider/data-provider-client";
import {
    TExcelExportData,
    gDataproviderRecordIndexName,
} from "@data/dataprovider/data-provider-server";
import recoilDesigner from "@data/designer";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    CheckboxVisibility,
    IColumnDragDropDetails,
    IGroup,
    IStyleFunctionOrObject,
    MessageBarType,
    ResponsiveMode,
    Shimmer,
} from "@fluentui/react";
import { SelectionMode } from "@fluentui/react/lib/Selection";
import {
    AdvValueBindingDefaultValue,
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvObjMemoRenderless } from "@hooks/useAdvObjMemo";
import { EAdvValueDataTypes } from "@utils/data-types";
import deepCopy from "@utils/deep-copy";
import { combineStyles } from "@utils/styling";
import assert from "assert";
import { nanoid } from "nanoid";
import React, { Ref, useMemo, useRef, useState } from "react";

import {
    TAdvLegacyTableColumn,
    TAdvLegacyTableItem,
    TAdvLegacyTableStyleProps,
    TAdvLegacyTableStyles,
} from "@components/data/table/table";
import AdvText from "@components/data/text";
import { gPageParamResultAutoVar } from "@components/dynamic/parameter-mapping/page-parameter";
import AdvButton from "@components/inputs/button/button";
import AdvDatePicker from "@components/inputs/datepicker";
import { StrToDate, TAdvDatePickerValueFormat } from "@components/inputs/datepicker/utils";
import AdvDropdown, { TAdvDropdownItem } from "@components/inputs/dropdown-new/dropdown";
import AdvSearchInput from "@components/inputs/text-input-new/search-input/search-input";
import AdvTextInput from "@components/inputs/text-input-new/text-input";
import { AdvContextualMenu } from "@components/layout/contextual-menu/contextual-menu";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { activePageName } from "@components/navigation/history";
import { TAdvCommonProperties } from "@components/other/common-properties";
import AdvIcon from "@components/other/icon/icon";
import AdvMessageBar from "@components/other/message-bar/message-bar";
import { EPageComponentSizeType, TPageComponentProps } from "@components/page-component";
import { cacheGet, cacheSet } from "@data/cache/cache";
import { LAN } from "@data/language/strings";
import { buildPageIDForVariableID, recoilParameterListOfCurrentPage } from "@data/parameters";
import {
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    RowRenderer,
} from "@fluentui-contrib/react-data-grid-react-window";
import {
    Button,
    DataGridCellProps,
    Divider,
    FluentProvider,
    Menu,
    MenuItem,
    MenuPopover,
    MenuTrigger,
    TableHeaderCellProps,
    TableRowId,
    // eslint-disable-next-line no-restricted-imports
    createTableColumn,
    useFluent,
    useScrollbarWidth,
} from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import useAdvToast from "@hooks/dialogs/useAdvToast";
import { useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { toAdvText, useT } from "@hooks/language/useTranslation";
import { useDebounceSetterFunc } from "@hooks/misc/useDebounce";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { createExcelFile } from "@hooks/useAdvExcel";
import useAdvTheme from "@hooks/useAdvTheme";
import { BarsIcon, FileExcel, FilterIcon, FilterResetIcon, RefreshIcon } from "@themes/icons";
import { ServerStrToLocalDateStr, ServerStrToLocalDateTimeStr } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { advcatch, advlog } from "@utils/logging";
import { Waypoint } from "react-waypoint";
import AdvCellValue, { columnIsNumberType } from "./cellValue";
import { AdvEndlessTableMobileModal } from "./mobileDataModal";
import { ContentBoxCreator, ResizerFlex } from "./resizer-flex";
import { AdvTableActionHeader } from "./table-action-header";
import {
    TAdvCustomColumnsDefinition,
    TAdvEndlessTableAutoGrouping,
    TAdvEndlessTableColumn,
    TAdvEndlessTableColumnActionType,
    TAdvEndlessTableColumnDefinitions,
    TAdvEndlessTableCrosstableAggregateType,
    TAdvEndlessTableDataPositioning,
    TAdvEndlessTableHeaderAction,
    TAdvEndlessTableHeaderFilters,
} from "./types";
import { DefaultEndlessTableColumn, EndlessTableMinMaxWidth, estimateFontSize } from "./utils";

const pageSize = 50;

const tableSelectionIconWidth = 44;
const tableExtraPaddingPerColumnLeft = 8;
const tableExtraPaddingPerColumnRight = 8;
const tableExtraPaddingPerColumn = tableExtraPaddingPerColumnLeft + tableExtraPaddingPerColumnRight;
const columnActionDatePickerWidth = 150;
const columnActionInputMinWidth = 100;

type TAdvTableProps = {
    styles?: IStyleFunctionOrObject<TAdvLegacyTableStyleProps, TAdvLegacyTableStyles>;

    columns: TAdvLegacyTableColumn[];
    items: TAdvLegacyTableItem[];

    /** Wird aufgerufen, wenn sich die Selection ändert. Funktioniert nur, wenn kein eigenes ``selection`` übergeben wurde. */
    onSelectionChanged?: (items: TAdvLegacyTableItem[]) => void;
    customFilterOptions?: Array<IPublicFilterCategory>;

    /** Default: FALSE */
    canFilter?: boolean;
    /** Default: TRUE */
    canSort?: boolean;

    /** Default: TRUE */
    drawTableHeader?: boolean;
    tableHeaderActions?: TAdvEndlessTableHeaderAction[];
    tableHeaderFilters?: TAdvEndlessTableHeaderFilters;

    defaultActionParams?: TAdvWebActionParams;

    /**
     * position the table to a specific row, if the page contains
     * a call result value.
     */
    dataPositioning?: TAdvEndlessTableDataPositioning;

    /** Default: Hidden */
    checkboxVisibility?: CheckboxVisibility;
    ignoreTranslation?: boolean;

    onContextMenu?: React.MouseEventHandler<HTMLElement> | undefined;

    selectionMode?: SelectionMode;
};

export type TAdvEndlessTableProps = TAdvDesignerComponentProps &
    TAdvCommonProperties &
    Omit<TAdvTableProps, "items" | "columns" | "name"> & {
        providerKey: string;
        canFilter: boolean;
        canFilterBindingParams?: TAdvValueBindingParams;
        canSort: boolean;
        canSortBindingParams?: TAdvValueBindingParams;
        name: string;
        nameBindingParams?: TAdvValueBindingParams;
        customColumnDefinitions?: TAdvEndlessTableColumnDefinitions; // AdvProperty

        keyRef?: string;
        pageProps?: TPageComponentProps;

        canExcelExport?: boolean;

        autoGrouping?: TAdvEndlessTableAutoGrouping;
        ignoreTranslation?: boolean;
    };

const gTableColOrderCacheKey = "advtable_colorder_";
type TTableColumnOrder = Record<string, number>;
const getTableColumnOrder = async (
    key: string | undefined,
): Promise<TTableColumnOrder | undefined> => {
    if (key == undefined) return undefined;
    const cacheItem = await cacheGet<TTableColumnOrder>(gTableColOrderCacheKey + key);
    if (cacheItem.item == null) return undefined;
    return cacheItem.item;
};

const AdvDataGridHeaderCell = React.forwardRef(
    (
        {
            children,
            bindings,
            forceHide,
            pageLayout,
            ...props
        }: TableHeaderCellProps & { pageLayout: EPageComponentSizeType } & {
            bindings?: TAdvCustomColumnsDefinition;
            forceHide: boolean;
        },
        ref: any,
    ) => {
        const [isHidden] = useAdvValueBinderNoDataType(
            bindings?.isHiddenBindingParams,
            bindings?.definition.isHidden ?? false,
            EAdvValueDataTypes.Boolean,
            0,
        );
        const [name] = useAdvValueBinderNoDataType(
            bindings?.nameBindingParams,
            children as string,
            EAdvValueDataTypes.String,
            0,
        );

        const { t: nameT } = useT(name);

        if (
            isHidden ||
            forceHide ||
            (pageLayout <= EPageComponentSizeType.Mobile &&
                bindings?.definition.hideInMobile === true)
        ) {
            return <></>;
        }

        return (
            <DataGridHeaderCell {...props} ref={ref}>
                <span
                    className="adv-fui-TableHeaderCell"
                    title={name}
                    style={{ cursor: "pointer" }}
                >
                    {nameT}
                </span>
            </DataGridHeaderCell>
        );
    },
);
AdvDataGridHeaderCell.displayName = "AdvDataGridHeaderCell";

const AdvDataGridCellInner = ({
    children,
    bindings,
    pageLayout,
    keyRef,
}: DataGridCellProps & { pageLayout: EPageComponentSizeType; keyRef: string | undefined } & {
    bindings?: TAdvCustomColumnsDefinition;
}) => {
    if (pageLayout <= EPageComponentSizeType.Mobile && bindings?.definition.hideInMobile === true) {
        return <></>;
    }

    return (
        <DataGridCell key={keyRef}>
            <span className="adv-fui-TableHeaderCell">{children}</span>
        </DataGridCell>
    );
};

const AdvDataGridCellUserBindings = ({
    children,
    bindings,
    ...props
}: DataGridCellProps & { pageLayout: EPageComponentSizeType; keyRef: string | undefined } & {
    bindings?: TAdvCustomColumnsDefinition;
}) => {
    if (bindings?.definition.isHidden ?? false) return <></>;
    return (
        <AdvDataGridCellInner {...props} bindings={bindings}>
            {children}
        </AdvDataGridCellInner>
    );
};

const AdvDataGridCellBindings = ({
    children,
    bindings,
    ...props
}: DataGridCellProps & { pageLayout: EPageComponentSizeType; keyRef: string | undefined } & {
    bindings?: TAdvCustomColumnsDefinition;
}) => {
    const [isHidden] = useAdvValueBinderNoDataType(
        bindings?.isHiddenBindingParams,
        bindings?.definition.isHidden ?? false,
        EAdvValueDataTypes.Boolean,
        0,
    );
    if (isHidden) return <></>;
    return (
        <AdvDataGridCellInner {...props} bindings={bindings}>
            {children}
        </AdvDataGridCellInner>
    );
};

const DblClicker = ({
    keyRef,
    dataArrayIndex,
    defaultActionParams,
    setDoDblClick,
    dpSetCurrentRecords,
    selections,
}: {
    keyRef: string | undefined;
    dataArrayIndex: number;
    defaultActionParams: any;
    setDoDblClick: (val: boolean) => void;
    dpSetCurrentRecords: (indices: number[]) => boolean;
    selections: Array<number>;
}) => {
    const [, , defaultAction, , , isDisabled] = useAdvWebAction(
        keyRef ?? "",
        dataArrayIndex,
        defaultActionParams,
    );
    useAdvEffect(() => {
        if (!isDisabled) {
            setDoDblClick(false);
            dpSetCurrentRecords(selections);
            defaultAction();
        }
    }, [defaultAction, dpSetCurrentRecords, isDisabled, selections, setDoDblClick]);

    return <div></div>;
};

type TSetExecAction =
    | { user: string; userIndex: number; params: TAdvWebActionParams | undefined }
    | undefined;

const AdvDataGridCellImpl = ({
    children,
    bindings,
    forceHide,
    action,
    actionType,
    keyRef,
    rawCellValue,
    rowID,
    setSelection,
    setShouldExecAction,
    storeValue,
    ...props
}: DataGridCellProps & {
    pageLayout: EPageComponentSizeType;
    action?: TAdvWebActionParams;
    actionType?: TAdvEndlessTableColumnActionType;
    keyRef?: string;
    rawCellValue?: string;
    cellId: string;
    rowID: number;
    setSelection: (rowid: Set<TableRowId>) => void;
    setShouldExecAction: (val: TSetExecAction) => void;
    storeValue: (val: string) => void;
} & {
    bindings?: TAdvCustomColumnsDefinition;
    forceHide: boolean;
}) => {
    const [, cellActionIcon] = useAdvWebAction(keyRef ?? "", 0, action);

    const [minValueText] = useAdvValueBinderNoDataType(
        bindings?.definition.actionMinValueBindingParams,
        bindings?.definition.actionMinValue,
        EAdvValueDataTypes.Any,
        0,
    );

    if (forceHide) return <></>;

    if (IsValueBindingTrivial(bindings?.isHiddenBindingParams)) {
        const isActionButton = action != undefined && actionType == "button";
        const isActionInput = action != undefined && actionType == "input";
        const isActionDatepicker = action != undefined && actionType == "datepicker";

        if (isActionButton) {
            return (
                <AdvDataGridCellUserBindings bindings={bindings} {...props} keyRef={keyRef}>
                    <div style={{ float: "left", marginTop: "5px" }}>{children}</div>
                    <AdvButton
                        advhide={bindings?.definition.isActionHidden}
                        advhideBindingParams={bindings?.definition.isActionHiddenBindingParams}
                        disabled={bindings?.definition.isActionDisabled}
                        disabledBindingParams={bindings?.definition.isActionDisabledBindingParams}
                        iconName={cellActionIcon}
                        onClick={() => {
                            setSelection(new Set([rowID]));
                            setTimeout(() => {
                                setShouldExecAction({
                                    user: keyRef ?? "",
                                    params: action,
                                    userIndex: 0,
                                });
                            }, 1);
                        }}
                        keyRef={keyRef ?? ""}
                        hideText={true}
                        simplified
                        styles={{
                            textContainer: {
                                textAlign: "left",
                            },
                            root: {
                                marginLeft: "5px",
                                backgroundColor: "transparent",
                                minWidth: "0",
                                paddingLeft: "0",
                                paddingRight: "0",
                                marginTop: "-1px",
                                position: "absolute",
                            },
                        }}
                    ></AdvButton>
                </AdvDataGridCellUserBindings>
            );
        } else if (isActionInput) {
            return (
                <AdvDataGridCellUserBindings
                    focusMode="cell"
                    bindings={bindings}
                    {...props}
                    keyRef={keyRef}
                >
                    <AdvTextInput
                        advhide={bindings?.definition.isActionHidden}
                        advhideBindingParams={bindings?.definition.isActionHiddenBindingParams}
                        disabled={bindings?.definition.isActionDisabled}
                        disabledBindingParams={bindings?.definition.isActionDisabledBindingParams}
                        value={rawCellValue}
                        submitAction={action}
                        type={"number"}
                        onValueChanged={(data) => {
                            if (data != undefined) {
                                storeValue(data);
                            }
                        }}
                        onFocusCapture={(e) => {
                            setSelection(new Set([rowID]));
                            e.target.select();
                        }}
                        selected={true}
                        keyRef={keyRef ?? ""}
                        min={minValueText}
                    ></AdvTextInput>
                </AdvDataGridCellUserBindings>
            );
        } else if (isActionDatepicker) {
            return (
                <AdvDataGridCellUserBindings
                    focusMode="cell"
                    bindings={bindings}
                    {...props}
                    keyRef={keyRef}
                >
                    <AdvDatePicker
                        translatableTextLabel={toAdvText("")}
                        advhide={bindings?.definition.isActionHidden}
                        advhideBindingParams={bindings?.definition.isActionHiddenBindingParams}
                        disabled={bindings?.definition.isActionDisabled}
                        disabledBindingParams={bindings?.definition.isActionDisabledBindingParams}
                        value={rawCellValue}
                        submitAction={action}
                        minDate={StrToDate(TAdvDatePickerValueFormat.SQL, minValueText)}
                        keyRef={keyRef ?? ""}
                        style={{ width: columnActionDatePickerWidth + "px" }}
                        onFocusCapture={() => {
                            setSelection(new Set([rowID]));
                        }}
                        onValueChanged={(data) => {
                            if (data != undefined) {
                                storeValue(data);
                            }
                        }}
                    ></AdvDatePicker>
                </AdvDataGridCellUserBindings>
            );
        } else {
            return (
                <AdvDataGridCellUserBindings bindings={bindings} {...props} keyRef={keyRef}>
                    <div style={{ float: "left", marginTop: "5px" }}>{children}</div>
                </AdvDataGridCellUserBindings>
            );
        }
    } else {
        return (
            <AdvDataGridCellBindings bindings={bindings} {...props} keyRef={keyRef}>
                {children}
            </AdvDataGridCellBindings>
        );
    }
};

const AdvDataGridCell = React.memo(AdvDataGridCellImpl);

/**
 * Eine Table, die immer wieder Daten nachlädt (aka EndlessScroller / Infinite Scrolling).
 *
 * Funktioniert nur in Kombination mit einem DataProvider.
 */
const AdvEndlessTableComp = ({
    keyRef,
    pageProps,
    styles: propStyles,
    providerKey,
    designerData,
    advhide,
    advhideBindingParams,
    canFilter,
    canSort,
    customColumnDefinitions,
    designerProps,
    name,
    nameBindingParams,
    canFilterBindingParams,
    canSortBindingParams,
    selectionMode,
    tableHeaderActions = [],
    tableHeaderFilters = [],
    defaultActionParams,
    dataPositioning,
    dataArrayIndex = 0,
    canExcelExport = false,
    autoGrouping,
    ...props
}: TAdvEndlessTableProps) => {
    useAdvComponent(AdvEndlessTableComp, props);

    const pageSizeType = pageProps?.sizeType ?? EPageComponentSizeType.DesktopWide;
    const isMobile = pageSizeType <= EPageComponentSizeType.Mobile;

    const theme = useAdvTheme();

    const [columnIndices, setColumnIndices] = useState<TTableColumnOrder>({});

    useAdvEffect(() => {
        getTableColumnOrder(keyRef)
            .then((val) => {
                if (val === undefined) return;
                setColumnIndices(val);
            })
            .catch(advcatch);
    }, [keyRef]);

    const {
        isLoaded: dpIsLoaded,
        getFields: dpGetFields,
        getFilterOptions: dpGetFilterOptions,
        getData: dpGetData,
        isEOF: dpIsEOF,
        setCurrentRecords: dpSetCurrentRecords,
        getCurrentRecords: dpGetCurrentRecords,
        loadData: dpLoadData,
        setFilterSort: dpSetFilterSort,
        setFilterSelection: dpSetFilterSelection,
        hasErrors: dpHasErrors,
        getErrors: dpGetErrors,
        getSorting: dpGetSorting,
        getSearchText: dpGetSearchText,
        setSearchText: dpSetSearchText,
        getFilterSelection: dpGetFilterSelection,
        downloadExcel: dpDownloadExcel,
        reload: dpReload,
        resetSort: dpResetSort,
    } = useDataprovider(providerKey, {
        [EDataproviderClientOptions.AutoLoadCount]: 50,
        [EDataproviderClientOptions.GetProviderFieldsWithoutData]: true,
    });

    const didPosition = useRef(false);
    const router = useAdvRouter();
    const variableID = useMemo(() => buildPageIDForVariableID(router.pageInfo), [router.pageInfo]);
    const pageVars = useAdvRecoilValue(recoilParameterListOfCurrentPage(variableID));
    const resultPageVar = useMemo(() => {
        return pageVars.get(gPageParamResultAutoVar);
    }, [pageVars]);

    const isDesignerMode = useAdvRecoilValue(recoilDesigner.settings.renderAsDesigner);

    const itemUniqueness = useMemo(() => nanoid(), []);

    const pageLoadListTimeouts = useRef<Array<any>>(new Array<any>());

    const isInsideDesigner = useMemo(() => designerProps != undefined, [designerProps]);

    /** Columns aus den Fields erstellen, sobald diese vom DataProvider bereitgestellt werden. */
    type TTableColumnResult = {
        columns: TAdvEndlessTableColumnDefinitions;
        widthOfAllColumns: number;
        crossvalueFields?: string[];
        crosslabelField?: string; //speichert zwischen ob unter den erstellten Columns auch welche aus der Kreuztabelle kommen, und welchem Feld die entspringen
        crossvalueAggregates?: Map<string, TAdvEndlessTableCrosstableAggregateType>;
    };

    const memodpGetData = useMemo(() => {
        return dpGetData();
    }, [dpGetData]);

    const columns = useAdvObjMemoRenderless<TTableColumnResult>(
        () => {
            let arrColumns: TAdvEndlessTableColumnDefinitions = [];

            if (
                dataPositioning != undefined &&
                dataPositioning.fieldName != "" &&
                resultPageVar != undefined &&
                resultPageVar.val != undefined &&
                // Wir müsse nur alle Daten laden, wenn wir nicht
                // eh schon EoF sind und alle Daten haben
                !dpIsEOF()
            ) {
                dpLoadData(0, -1);
            } else if (
                customColumnDefinitions != undefined &&
                // Wir müsse nur alle Daten laden, wenn wir nicht
                // eh schon EoF sind und alle Daten haben
                !dpIsEOF() &&
                customColumnDefinitions?.findIndex((col) => {
                    return col.definition.isCrossvalue ?? false;
                }) > -1
            ) {
                //wenn es ein crossvalue gibt, muss auch alles geladen werden (dann haben wir ja eine Kreuztabelle)
                dpLoadData(0, -1);
            }

            const isDpLoaded = dpIsLoaded();

            let crosslabelField: string = "";
            let crosslabelFieldSettings: IFieldSettings | undefined = undefined;
            const crossvalueColumns: TAdvEndlessTableColumn[] = []; //alle Spalteninfos fuer die crossvalues
            const crossvalueFields: string[] = []; //nur die Feldnamen der crossvalues
            const crossvalueAggregates = new Map<string, TAdvEndlessTableCrosstableAggregateType>();

            const fields = dpGetFields();
            if (isDpLoaded) {
                // Standardmäßig immer alle Spalten anzeigen (Fields z.B. KUNDEN_ID)
                advlog("fields", fields);
                if (fields != undefined) {
                    // Wir haben eigene Spaltendefinition? Dann nutzen wir diese.
                    if (customColumnDefinitions && customColumnDefinitions.length > 0) {
                        // Spalten, die NICHT in der Definition sind, werden auch NICHT angezeigt
                        customColumnDefinitions.forEach((col) => {
                            //wird diese Spalte zum Bauen einer Kreuztabelle verwendet? wenn ja, nicht erstellen
                            if (col.definition.isCrosslabel ?? false) {
                                crosslabelField = col.definition.fieldName;
                                crosslabelFieldSettings =
                                    fields[crosslabelField]?.Settings ?? undefined;
                            } else if (col.definition.isCrossvalue ?? false) {
                                crossvalueColumns.push(col.definition);
                                crossvalueFields.push(col.definition.fieldName);
                            } else {
                                //wenn nicht, ganz normal hinzufuegen
                                const newCol = deepCopy(col);
                                newCol.field = fields[newCol.definition.fieldName];
                                arrColumns.push(newCol);
                            }
                        });
                    } else {
                        arrColumns = Object.keys(fields)
                            .sort((val1, val2) => {
                                return fields[val1].ColumnIndex - fields[val2].ColumnIndex;
                            })
                            .map((fieldName) => {
                                return DefaultEndlessTableColumn(
                                    fieldName,
                                    fields[fieldName],
                                    undefined,
                                );
                            });
                    }
                }
            }

            // Im Designer: Klicken auf die Spalten soll ebenfalls diese Komponente auswählen
            if (designerProps != undefined && arrColumns.length > 0) {
                if (Object.isFrozen(arrColumns) || Object.isFrozen(arrColumns[0]))
                    arrColumns = deepCopy(arrColumns);
                arrColumns.forEach((col) => (col.definition.onColumnClick = designerProps.onClick));
            }

            const data = memodpGetData;

            arrColumns.splice(
                0,
                0,
                ...[
                    {
                        definition: {
                            fieldName: "-",
                            name: "-",
                            idealWidth: 0,
                            isHidden: true,
                            key: "empty_front",
                        },
                        isHiddenBindingParams: deepCopy(AdvValueBindingDefaultValue),
                        isResizableBindingParams: deepCopy(AdvValueBindingDefaultValue),
                        maxWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                        minWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                        nameBindingParams: deepCopy(AdvValueBindingDefaultValue),
                    } as TAdvCustomColumnsDefinition,
                ],
            );

            if (crosslabelField !== "" && crossvalueColumns.length > 0) {
                //hier werden die Spalten aus dem crosslabelField erstellt (zB eine Spalte pro Jahr)
                if (data != undefined && typeof data[crosslabelField] != "undefined") {
                    const createdColumns: string[] = [];
                    data[crosslabelField].Values.toSorted().forEach((val) => {
                        //fuer jeden Wert (als string) soll es eine (bei mehreren Werten mehrere) Spalte(n) geben
                        if (!createdColumns.includes(val as string)) {
                            createdColumns.push(val as string);
                            //fuer die korrekte Darstellung eines Datums noch gemaess Feldtyp formatieren:
                            let valString = val as string;
                            switch (crosslabelFieldSettings?.FieldType) {
                                case EFieldSettingsFieldTypes.datetime:
                                    valString = ServerStrToLocalDateTimeStr(valString);
                                case EFieldSettingsFieldTypes.date:
                                    valString = ServerStrToLocalDateStr(valString);
                            }
                            crossvalueColumns.forEach((aCrossvalueColumn) => {
                                //hiermit iterieren wir durch potentiell mehrere aufgeteilte Spalten
                                arrColumns.push({
                                    definition: {
                                        //wie dieser fieldName aufgebaut ist, ist auch wichtig fuer die Zuordnung spaeter
                                        fieldName:
                                            aCrossvalueColumn.fieldName +
                                            "_" +
                                            crosslabelField +
                                            "_" +
                                            (val as string),
                                        idealWidth: 1,
                                        name:
                                            valString +
                                            (crossvalueColumns.length > 1
                                                ? " (" + aCrossvalueColumn.name + ")"
                                                : ""),
                                        key:
                                            "crosslabel_" +
                                            aCrossvalueColumn.fieldName +
                                            "_" +
                                            crosslabelField +
                                            "_" +
                                            (val as string),
                                        crosstableValueSourcefield: aCrossvalueColumn.fieldName,
                                        //Einstellungen fuer die Spalten uebernehmen wir von crossvalue
                                        hideInMobile: aCrossvalueColumn.hideInMobile,
                                        isHidden: aCrossvalueColumn.isHidden,
                                        isResizable: aCrossvalueColumn.isResizable,
                                    },
                                    isHiddenBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                    isResizableBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                    maxWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                    minWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                    nameBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                    field: fields ? fields[aCrossvalueColumn.fieldName] : null,
                                });
                            });
                        }
                    });
                    //Aggregatsspalten hinzufuegen
                    crossvalueColumns.forEach((aCrossvalueColumn) => {
                        const aggregateType = aCrossvalueColumn.crossvalueAggregateType; //könnte man noch erweitern und anpassbar machen
                        if (aggregateType != undefined && aggregateType?.label != undefined) {
                            crossvalueAggregates.set(aCrossvalueColumn.fieldName, aggregateType);
                            const aggregateTitle = aggregateType.label;
                            arrColumns.push({
                                definition: {
                                    //wie dieser fieldName aufgebaut ist, ist auch wichtig fuer die Zuordnung spaeter
                                    fieldName:
                                        aCrossvalueColumn.fieldName + "_" + aggregateType.short,
                                    idealWidth: 1,
                                    name:
                                        aggregateTitle +
                                        (crossvalueColumns.length > 1
                                            ? " (" + aCrossvalueColumn.name + ")"
                                            : ""),
                                    key:
                                        "crosslabel_" +
                                        "aggregate_" +
                                        aggregateType.short +
                                        aCrossvalueColumn.fieldName,
                                    crosstableValueSourcefield: aCrossvalueColumn.fieldName,
                                    //Einstellungen fuer die Spalten uebernehmen wir von crossvalue
                                    hideInMobile: aCrossvalueColumn.hideInMobile,
                                    isHidden: aCrossvalueColumn.isHidden,
                                    isResizable: aCrossvalueColumn.isResizable,
                                },
                                isHiddenBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                isResizableBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                maxWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                minWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                nameBindingParams: deepCopy(AdvValueBindingDefaultValue),
                                field: fields ? fields[aCrossvalueColumn.fieldName] : null,
                            });
                        }
                    });
                } else {
                    console.error(
                        "Fehler beim Versuch, Kreuztabellen-Spalten zu erstellen. Entweder keine Daten oder ein undefined crosslabelfield.s",
                    );
                }
            }

            const isMobile =
                (pageProps?.sizeType ?? EPageComponentSizeType.DesktopWide) <=
                EPageComponentSizeType.Mobile;
            arrColumns.push({
                definition: {
                    fieldName: "",
                    idealWidth: isMobile ? 50 : 1,
                    name: "",
                    key: "empty_back",
                },
                isHiddenBindingParams: deepCopy(AdvValueBindingDefaultValue),
                isResizableBindingParams: deepCopy(AdvValueBindingDefaultValue),
                maxWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                minWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
                nameBindingParams: deepCopy(AdvValueBindingDefaultValue),
                field: null,
            });

            const sortedCols = dpGetSorting();
            arrColumns.forEach((col) => {
                if (col.definition.fieldName == "" || col.definition.fieldName == "-") return;
                const foundColIndex = sortedCols.findIndex(
                    (val) => val.name === col.definition.fieldName,
                );
                col.definition.isSorted = false;
                if (foundColIndex != -1) {
                    col.definition.isSorted = true;
                    col.definition.isSortedDescending = sortedCols[foundColIndex].sortDesc;
                }

                // determine automatic column width
                let fieldNameForColumnWidth = col.definition.fieldName;
                //falls Kreuztabellen-Werte-Spalte, dann anderes Feld als Grundlage nehmen
                if (
                    col.definition.crosstableValueSourcefield != null ||
                    col.definition.crosstableValueSourcefield != undefined
                ) {
                    fieldNameForColumnWidth = col.definition.crosstableValueSourcefield ?? "";
                }
                let fieldWidth: undefined | number = undefined;
                const minFieldWidth = 1;

                if (
                    data != undefined &&
                    fieldNameForColumnWidth != "" &&
                    typeof data[fieldNameForColumnWidth] != "undefined"
                ) {
                    fieldWidth = Math.max(
                        EndlessTableMinMaxWidth(col.definition.name, false).minWidth,
                        fieldWidth ?? minFieldWidth,
                    );
                    data[fieldNameForColumnWidth].Values.every((val, index) => {
                        if (index >= pageSize) return false;
                        if (typeof val == "string") {
                            fieldWidth = Math.max(estimateFontSize(val), fieldWidth ?? 1);
                        } else if (typeof val != "undefined") {
                            fieldWidth = Math.max(
                                estimateFontSize(JSON.stringify(val)),
                                fieldWidth ?? minFieldWidth,
                            );
                        } else fieldWidth = fieldWidth ?? minFieldWidth;
                        return true;
                    });
                }
                col.definition.idealWidth = fieldWidth ?? minFieldWidth;

                // disable the ugly drag icon
                col.definition.styles = {
                    gripperBarVerticalStyle: { display: "none !important" },
                    cellTitle: {
                        justifyContent:
                            fields != undefined &&
                            col.definition.fieldName in fields &&
                            columnIsNumberType(fields[col.definition.fieldName].Settings.FieldType)
                                ? "end"
                                : undefined,
                    },
                };

                if (
                    col.definition.actionType == "datepicker" &&
                    col.definition.idealWidth < columnActionDatePickerWidth
                ) {
                    col.definition.idealWidth = columnActionDatePickerWidth;
                } else if (
                    col.definition.actionType == "input" &&
                    col.definition.idealWidth < columnActionInputMinWidth
                ) {
                    col.definition.idealWidth = columnActionInputMinWidth;
                }
            });
            // column order
            // first sort the column indices
            const columnIndicesKeys = Object.keys(columnIndices).sort((val1, val2) => {
                return columnIndices[val1] - columnIndices[val2];
            });
            // split the array
            const colsWithIndex = arrColumns
                .filter((col) => {
                    return (
                        columnIndicesKeys.find((ind) => ind == col.definition.fieldName) !=
                        undefined
                    );
                })
                .sort(
                    (val1, val2) =>
                        columnIndices[val1.definition.fieldName] -
                        columnIndices[val2.definition.fieldName],
                );
            const colsWithoutIndex = arrColumns.filter(
                (col) =>
                    columnIndicesKeys.find((ind) => ind == col.definition.fieldName) == undefined,
            );
            // create the new sorted col
            const res: TTableColumnResult = {
                columns: [],
                widthOfAllColumns: 0,
                crosslabelField: crosslabelField,
                crossvalueFields: crossvalueFields,
                crossvalueAggregates: crossvalueAggregates,
            };
            let index = 0;
            colsWithoutIndex.forEach((col) => {
                while (
                    colsWithIndex.length > 0 &&
                    columnIndices[colsWithIndex[0].definition.fieldName] == index
                ) {
                    res.columns.push(colsWithIndex[0]);
                    colsWithIndex.shift();
                    ++index;
                }
                res.columns.push(col);
                ++index;
            });
            // add all columns that werent added yet
            res.columns.push(...colsWithIndex);
            // icon in front
            res.widthOfAllColumns += tableSelectionIconWidth;
            res.columns.forEach(
                (col) =>
                    (res.widthOfAllColumns +=
                        col.definition.idealWidth + tableExtraPaddingPerColumn),
            );

            // aggressively make the endless table not load while
            // calculations aren't finished
            if (!isDpLoaded || data == undefined) {
                res.widthOfAllColumns = 0;
            }

            // Solange wir noch keine Columns haben: Nichts anzeigen
            return res;
        },
        [
            dataPositioning,
            resultPageVar,
            dpIsEOF,
            customColumnDefinitions,
            dpIsLoaded,
            dpGetFields,
            designerProps,
            memodpGetData,
            pageProps?.sizeType,
            dpGetSorting,
            columnIndices,
            dpLoadData,
        ],
        () => {
            return { columns: [], widthOfAllColumns: 0 };
        },
        deepCompareJSXProps,
    );

    const filter = useMemo<Array<IPublicFilterCategory>>(() => {
        let result: Array<IPublicFilterCategory> = [];
        const filterOptions = dpGetFilterOptions().filter((val) => val.Name != "");
        if (filterOptions !== undefined) {
            result = filterOptions;
        }

        return result;
    }, [dpGetFilterOptions]);

    type TGroupedItem = {
        indexOfKind: number;
        kind: "group" | "item" | "null" | "group_waypoint" | "item_waypoint" | "null_waypoint";
        additionalRepresentedIOKs: number[]; //wenn ein GroupedItem weitere Datensaetze vertritt, steht das hier drin
    };
    type TGroupedItems = Array<TGroupedItem>;
    type TItems = Array<Record<string, any> | null>;
    type TItemsToRenderItemsMapping = Array<number>;
    type TTableData = {
        items: TItems;
        groups: IGroup[];
        renderItems: TGroupedItems;
        //renderItems entspricht den dargestellten Zeilen, haelt aber selbst keine Daten sondern nur indizes
        itemsToRenderItemsMapping: TItemsToRenderItemsMapping;
        //items entsprechen den Daten aus dem DP, itemsToRenderItemsMapping also die Zuordnung von DP-Index zu renderItems-Index (bzw rowIndex)
        recordIndexPageCallResult: number;
    };

    // Tatsächlich sichtbare Items (NULL = MissingItem)
    const tableData = useAdvObjMemoRenderless<TTableData>(
        () => {
            const res: TTableData = {
                items: [],
                groups: [],
                renderItems: [],
                itemsToRenderItemsMapping: [],
                recordIndexPageCallResult: -1,
            };

            const hasAutoGrouping =
                autoGrouping != undefined &&
                autoGrouping.groupByField != "" &&
                autoGrouping.groupByField != undefined;

            const groupByField = autoGrouping?.groupByField ?? "";

            const isDesignerModus =
                (designerData && isDesignerMode.IsLoaded() && isDesignerMode.Get().Value) ?? false;

            const provData = dpGetData();
            if (provData && !dpHasErrors()) {
                const keys = Object.keys(provData);
                if (keys.length > 0) {
                    const _items: (Record<string, any> | null)[] = [];
                    const groups: { value: string; startIndex: number; count: number }[] = [];
                    let lastGroup: string | undefined = undefined;
                    let provDataItemCount = 0;
                    keys.map((k) => {
                        if (provData[k].Values.length > 0)
                            provDataItemCount = provData[k].Values.length;
                    });
                    const itemCount = Math.min(
                        provDataItemCount,
                        !isDesignerModus ? Number.MAX_SAFE_INTEGER : 20,
                    );

                    // Jeder Eintrag bzw. Datensatz benötigt einen EINDEUTIGEN KEY
                    // Da bietet sich doch die ID an :)
                    const hasKeyColumn = keys.indexOf("key") >= 0; // Falls wir doch eine "key" Spalte haben, dann diese nehmen
                    const aIDColumnKey = keys.find((key) => key.toUpperCase().endsWith("ID")); // Ansonsten nach einer ID Spalte suchen

                    if (hasKeyColumn == false && aIDColumnKey === undefined)
                        console.warn("Weder Key- noch ID-Spalte vorhanden! Fallback: Index");

                    for (let i = 0; i < itemCount; i++) {
                        const temp: any = {};

                        for (const key of keys) {
                            temp[key] = provData[key].Values[i];
                        }

                        if (hasKeyColumn == false) {
                            temp["key"] = itemUniqueness + "___" + i.toString();
                        }

                        // check if group must be added
                        if (
                            hasAutoGrouping &&
                            autoGrouping != undefined &&
                            keys.includes(groupByField)
                        ) {
                            const groupByValue = (
                                provData[groupByField].Values[i] as any
                            ).toString();
                            if (groupByValue != undefined) {
                                if (lastGroup === undefined || groupByValue != lastGroup) {
                                    lastGroup = groupByValue;

                                    let displayText = "";
                                    if (
                                        autoGrouping.displayText === undefined ||
                                        autoGrouping.displayFields === undefined
                                    ) {
                                        displayText = groupByValue;
                                    } else {
                                        displayText = autoGrouping.displayText;
                                        const displayFieldValues = autoGrouping.displayFields.map(
                                            (field) =>
                                                (provData[field].Values[i] as any).toString(),
                                        );
                                        displayFieldValues.forEach(
                                            (value, index) =>
                                                (displayText = displayText.replace(
                                                    `{${index}}`,
                                                    value,
                                                )),
                                        );
                                    }
                                    res.renderItems.push({
                                        indexOfKind: groups.length,
                                        kind: "group",
                                        additionalRepresentedIOKs: [],
                                    });
                                    groups.push({ value: displayText, startIndex: i, count: 0 });
                                }
                            }

                            if (groups.length > 0) {
                                const gIndex = groups.length - 1;
                                ++groups[gIndex].count;
                            }
                        }

                        res.itemsToRenderItemsMapping.push(res.renderItems.length);
                        res.renderItems.push({
                            indexOfKind: _items.length,
                            kind: "item",
                            additionalRepresentedIOKs: [],
                        });
                        _items.push(temp);

                        if (
                            dataPositioning != undefined &&
                            dataPositioning.fieldName != "" &&
                            resultPageVar != undefined
                        ) {
                            if (keys.includes(dataPositioning.fieldName)) {
                                if (
                                    provData[dataPositioning.fieldName].Values[i] ==
                                    resultPageVar.val
                                ) {
                                    res.recordIndexPageCallResult = i;
                                }
                            }
                        }
                    }

                    if (
                        columns.crosslabelField != undefined &&
                        columns.crossvalueFields != undefined &&
                        columns.crossvalueFields.length > 0
                    ) {
                        //hier wird die Zuordnung Mutterzeilen/Tochterzeilen erstellt
                        //Mutterzeile: die Zeile die normal gebaut werden wird
                        //Tochterzeile: eine Zeile, die alle Informationen (außer crosslabel und crossvalue) mit der Mutterzeile gleich hat
                        //von der Tochterzeile wird nur der eine Wert (crossvalue) genommen und in die entsprechende Spalte (crosslabel) gepackt, nicht die ganze Zeile gebaut
                        const crosslabelField: string = columns.crosslabelField;
                        const crossvalueFields: string[] = columns.crossvalueFields;
                        const crossvalueAggregates = columns.crossvalueAggregates;
                        const processedIds: Array<number> = [];
                        const motherIds: Array<number> = [];

                        res.renderItems.forEach((aRenderItem) => {
                            if (aRenderItem.kind != "item") {
                                return;
                            }
                            const anIndex: number = aRenderItem.indexOfKind;
                            const anItem = _items[anIndex];

                            if (processedIds.includes(anIndex)) return;
                            processedIds.push(anIndex);

                            if (anItem === null) {
                                console.warn(
                                    "Bei der Zuordnung der Zeilen wegen Kreuztabelle ist ein Element === null aufgetaucht und wird übersprungen.",
                                );
                                return;
                            }

                            const didFindMotherRow = motherIds.some((aMotherId) => {
                                //sucht solange, bis eine Mutterzeile (fast alles stimmt überein) gefunden ist
                                const hasDifferentValues = columns.columns.some((col) => {
                                    //sucht solange, bis ein nicht-übereinstimmender Wert gefunden ist
                                    //leere Felder und solche, die nur crosstable-Spalten sind, ignorieren:
                                    if (
                                        col.definition.fieldName == "" ||
                                        col.definition.fieldName == "-" ||
                                        col.definition.crosstableValueSourcefield != undefined
                                    ) {
                                        return false;
                                    }
                                    if (col.definition.fieldName != "") {
                                        const aMotherItemData = tableData.items[aMotherId];
                                        if (aMotherItemData != null && anItem != null) {
                                            //ist der Wert in dieser Spalte verschieden? falls ja: raus aus dem some-Block
                                            return (
                                                aMotherItemData[col.definition.fieldName] !==
                                                anItem[col.definition.fieldName]
                                            );
                                        }
                                    }
                                });
                                if (hasDifferentValues) {
                                    //stimmen nicht ueberein
                                    //diese Zeile (aMotherId) wird nicht zur Mutterzeile des aktuellen anItem
                                    return false;
                                } else {
                                    //stimmen ueberein, wir haben eine Mutterzeile gefunden
                                    const aMotherItem = _items[aMotherId];
                                    //die gerenderte Zeile repraesentiert jetzt auch den zusaetzlichen Tochterdatensatz:
                                    res.renderItems[
                                        res.itemsToRenderItemsMapping[aMotherId]
                                    ].additionalRepresentedIOKs.push(anIndex);
                                    if (_items !== null && aMotherItem !== null) {
                                        //Werte aus der Tochterzeile zu der Mutterzeile hinzufuegen
                                        crossvalueFields.forEach((aCrossvalueField) => {
                                            if (anItem[aCrossvalueField] == 0) {
                                                return; //0-Werte sollen nicht angezeigt werden
                                            }
                                            const newItemKey =
                                                aCrossvalueField +
                                                "_" +
                                                crosslabelField +
                                                "_" +
                                                (anItem[crosslabelField] as string); //der zusammengesetzte fieldName entspricht dem fieldName der erstellten Spalte!
                                            if (
                                                aMotherItem[newItemKey] !== undefined &&
                                                aMotherItem[newItemKey] !== null
                                            ) {
                                                //es gibt bereits Werte fuer diesen Eintrag, fallback: Summe bzw Stringausgabe
                                                if (
                                                    typeof +aMotherItem[newItemKey] == "number" &&
                                                    !Number.isNaN(+aMotherItem[newItemKey])
                                                ) {
                                                    aMotherItem[newItemKey] =
                                                        +aMotherItem[newItemKey] +
                                                        +anItem[aCrossvalueField];
                                                } else if (
                                                    typeof aMotherItem[newItemKey] == "string"
                                                ) {
                                                    aMotherItem[newItemKey] = "mehrere Werte";
                                                } else {
                                                    console.warn(
                                                        "Bei Erstellen der Kreuztabelle: Doppelter Wert fuer " +
                                                            newItemKey +
                                                            " ist weder string noch int. Bisherige Mutterzeile: " +
                                                            JSON.stringify(aMotherItem) +
                                                            ". Passende Tochterzeile: " +
                                                            JSON.stringify(anItem) +
                                                            ".",
                                                    );
                                                    aMotherItem[newItemKey] = "mehrere Werte"; //TODO: hier ein String reinzuschreiben kann fuer Probleme sorgen (Anzeige als NaN, falls Spaltentyp zB number)
                                                }
                                            } else {
                                                aMotherItem[newItemKey] = anItem[aCrossvalueField];
                                            }
                                            const aggregateType =
                                                crossvalueAggregates != undefined
                                                    ? crossvalueAggregates.get(aCrossvalueField)
                                                    : undefined;
                                            if (
                                                aggregateType != undefined &&
                                                aggregateType?.label != undefined &&
                                                !isNaN(anItem[aCrossvalueField])
                                            ) {
                                                const oldValue =
                                                    aMotherItem[
                                                        aCrossvalueField + "_" + aggregateType.short
                                                    ] * 1; //der zusammengesetzte fieldName entspricht dem fieldName der oben erstellten Aggregatspalte!
                                                const thisValue = anItem[aCrossvalueField] * 1;
                                                const newValue = aggregateType.newValCalc(
                                                    oldValue,
                                                    thisValue,
                                                );

                                                //Wert dieses anItem zur Aggregatsspalte hinzufuegen
                                                //der zusammengesetzte fieldName entspricht dem fieldName der oben erstellten Aggregatspalte!
                                                aMotherItem[
                                                    aCrossvalueField + "_" + aggregateType.short
                                                ] = newValue;
                                            }
                                        });
                                    } else {
                                        console.error(
                                            "Fehler beim Eintragen von Tochterdaten in Mutterzeile: Itemliste ist null oder Mutteritem ist null.",
                                        );
                                    }
                                    return true; //raus aus dem some-Block
                                }
                            });
                            if (!didFindMotherRow) {
                                //keine Mutterzeile gefunden, selbst zur Mutterzeile werden
                                let hasOnlyZeros = true; //Zeilen wo nur 0-Werte sind, sollen nicht neue Mutterzeile werden
                                //eigenen Wert in entsprechendes Feld eintragen
                                if (anItem !== null) {
                                    crossvalueFields.forEach((aCrossvalueField) => {
                                        if (anItem[aCrossvalueField] == 0) {
                                            return; //0-Werte sollen nicht angezeigt werden
                                        } else if (hasOnlyZeros) {
                                            hasOnlyZeros = false; //zwischenspeichern, dass es einen nicht-0-Wert gab
                                        }
                                        anItem[ //der zusammengesetzte fieldName entspricht dem fieldName der erstellten Spalte!
                                            aCrossvalueField +
                                                "_" +
                                                crosslabelField +
                                                "_" +
                                                (anItem[crosslabelField] as string)
                                        ] = anItem[aCrossvalueField];
                                        //Aggregatsspalte zu dieser neuen Mutterzeile hinzufuegen
                                        const aggregateType =
                                            crossvalueAggregates != undefined
                                                ? crossvalueAggregates.get(aCrossvalueField)
                                                : undefined;
                                        if (
                                            aggregateType != undefined &&
                                            aggregateType?.label != undefined &&
                                            !isNaN(anItem[aCrossvalueField])
                                        ) {
                                            anItem[aCrossvalueField + "_" + aggregateType.short] =
                                                anItem[aCrossvalueField]; //der zusammengesetzte fieldName entspricht dem fieldName der oben erstellten Aggregatspalte!
                                        }
                                    });
                                } else {
                                    console.error(
                                        "Fehler beim Eintragen von eigenen Daten beim erstellen einer neuen Mutterzeile: Item ist null.",
                                    );
                                }
                                if (!hasOnlyZeros) motherIds.push(anIndex);
                            }
                        });

                        //Entfernen der Tochterzeilen und Neubauen des mapping
                        const newRenderItems: TGroupedItems = [];
                        res.renderItems.forEach((aGroupedItem) => {
                            if (aGroupedItem.kind != "item") {
                                //nur items beachten, alles andere (groups, waypoints) einfach uebernehmen
                                newRenderItems.push(aGroupedItem);
                            } else if (motherIds.includes(aGroupedItem.indexOfKind)) {
                                res.itemsToRenderItemsMapping[aGroupedItem.indexOfKind] =
                                    newRenderItems.length;
                                aGroupedItem.additionalRepresentedIOKs.forEach((anIOK) => {
                                    //auch alle weiteren von dieser Zeile repraesentierten items (zB durch Kreuztabelle) sollen darauf gemappt werden
                                    res.itemsToRenderItemsMapping[anIOK] = newRenderItems.length;
                                });
                                newRenderItems.push(aGroupedItem);
                            }
                        });
                        res.renderItems = newRenderItems;
                    }

                    const mappedGroups = groups.map((g, gIndex) => {
                        return {
                            key: gIndex.toString(),
                            name: g.value,
                            startIndex: g.startIndex,
                            count: g.count,
                            level: 0,
                            isCollapsed:
                                allGroupsCollapsed.current ||
                                collapsedGroupsArray.current.indexOf(gIndex.toString()) >= 0,
                        } as IGroup;
                    });

                    if ((!isDesignerModus && !dpIsEOF()) || _items.length == 0) {
                        _items.push(null);
                        res.renderItems.push({
                            indexOfKind: -1,
                            kind: "null_waypoint",
                            additionalRepresentedIOKs: [],
                        });
                        const waypointOffset = 40;
                        if (res.renderItems.length >= waypointOffset) {
                            const kind =
                                res.renderItems[res.renderItems.length - waypointOffset].kind;
                            switch (kind) {
                                case "item":
                                    {
                                        res.renderItems[
                                            res.renderItems.length - waypointOffset
                                        ].kind = "item_waypoint";
                                    }
                                    break;
                                case "group":
                                    {
                                        res.renderItems[
                                            res.renderItems.length - waypointOffset
                                        ].kind = "group_waypoint";
                                    }
                                    break;
                                case "null":
                                    {
                                        res.renderItems[
                                            res.renderItems.length - waypointOffset
                                        ].kind = "null_waypoint";
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }

                        if (hasAutoGrouping) {
                            // Wenn wir noch Daten zum nachladen haben (letztes item = NULL) dann eine eigene Gruppe
                            // für das NULL-Item hinzufügen. Somit kann es nicht vorkommen, dass das NULL-Item in einer
                            // versteckten (collapsed) Gruppe landet
                            if (mappedGroups.length > 0)
                                mappedGroups[mappedGroups.length - 1].count--; // NULL-Element in eigener Gruppe
                            mappedGroups.push({
                                key: "loading_group",
                                name: '"loading"',
                                startIndex: tableData.items.length - 1,
                                count: 1,
                                level: 0,
                            });
                        }
                    }

                    res.groups = mappedGroups;
                    res.items = _items;
                    return res;
                }
            }

            res.items = [null];
            res.renderItems = [
                { indexOfKind: -1, kind: "null_waypoint", additionalRepresentedIOKs: [] },
            ];
            return res;
        },
        [
            autoGrouping,
            dataPositioning,
            designerData,
            dpGetData,
            dpHasErrors,
            dpIsEOF,
            isDesignerMode,
            itemUniqueness,
            resultPageVar,
            columns,
        ],
        () => {
            return {
                items: [null],
                groups: [],
                renderItems: [],
                itemsToRenderItemsMapping: [],
                recordIndexPageCallResult: -1,
            };
        },
        (a, b) => {
            // make sure that [null] arrays are always unique, as they might have to retrigger the whole table
            if (b.items.length == 1 && b.items[0] == null) return false;
            return JSON.stringify(a.items) == JSON.stringify(b.items);
        },
    );

    const collapsedGroupsArray = useRef<string[]>([]); // Merken welche Gruppen collapsed sind
    const allGroupsCollapsed = useRef<boolean>(false);

    // In FluentUI kann man die Auswahl (Selection) nur über diese Klasse steuern
    const [selections, setSelections] = useState<Array<number>>([]);

    const updateSelection = useAdvCallback(
        (selectedIndicesSorted: number[]) => {
            setSelections((old) => {
                // Wenn wir nur eine Reihe selektieren können, dann
                // können wir uns den verlgeich über JSON sparen.
                // Nur vergleichen, ob sich die Länge geändert hat oder
                // die Werte geändert haben.
                if (selectionMode == SelectionMode.single) {
                    if (
                        selectedIndicesSorted.length == old.length &&
                        old.length != 0 &&
                        selectedIndicesSorted[0] == old[0]
                    ) {
                        return old;
                    } else {
                        return selectedIndicesSorted;
                    }
                } else {
                    const res: Array<number> = [];
                    const prevIndices = old;
                    if (JSON.stringify(selectedIndicesSorted) != JSON.stringify(prevIndices)) {
                        selectedIndicesSorted.forEach((addIndex) => {
                            res.push(addIndex);
                        });
                        return res;
                    } else return old;
                }
            });
        },
        [selectionMode],
    );

    const setSelectionsWrapper = useAdvCallback(
        (clickedRows: Set<TableRowId>) => {
            const remainingRows: number[] = [];

            const clickedRowsArray = Array.from(clickedRows);
            clickedRowsArray.forEach((index) => {
                remainingRows.push(index as number);
            });

            if (remainingRows.length > 0) updateSelection(remainingRows);
            else updateSelection([0]); //bei keiner Auswahl (zB auch ueber "alle auswaehlen" in der Kopfzeile) wird das erste Element gewaehlt
        },
        [updateSelection],
    );

    const { hasErr, errs } = useMemo<{ hasErr: boolean; errs: string[] }>(() => {
        if (dpHasErrors()) {
            const errs = dpGetErrors();
            return { hasErr: true, errs: errs };
        }
        return { hasErr: false, errs: [] };
    }, [dpGetErrors, dpHasErrors]);

    /**
     * ``onWaypointEnter`` wird aufgerufen, wenn ein *NULL*-Item gerendert wird.
     * Das letzte Item in ``Items`` ist immer ein *NULL*-Item,
     * entsprechend können wir so erkennen wann neue Daten geladen werden müssen.
     *
     * Beachte: Dieses Event wird nicht nur 1x sondern öfters (bis zu 5x...) pro *NULL*-Item aufgerufen
     */
    const onWaypointEnter = useAdvCallback(
        (index?: number) => {
            if (index !== undefined) {
                const timeoutID = setTimeout(() => {
                    dpLoadData(index);
                    const timeoutIndex = pageLoadListTimeouts.current.indexOf(timeoutID);
                    if (timeoutIndex != -1) pageLoadListTimeouts.current.splice(timeoutIndex, 1);
                }, 0);
                pageLoadListTimeouts.current.push(timeoutID);
            }
        },
        [dpLoadData],
    );

    const renderNullItem = useAdvCallback(() => {
        // Solange noch keine Daten vorhanden sind: Lade-Animation anzeigen
        if (hasErr) {
            return (
                <AdvMessageBar type={MessageBarType.error}>
                    <AdvStack>
                        {errs.map((err, errIndex) => {
                            return (
                                <AdvStackItem key={"tabErr" + errIndex.toString()}>
                                    {err}
                                </AdvStackItem>
                            );
                        })}
                    </AdvStack>
                </AdvMessageBar>
            );
        } else if (dpIsEOF() && (tableData.items.length == 0 || tableData.items[0] == null)) {
            return (
                <AdvMessageBar type={MessageBarType.info}>
                    <AdvText styles={{ root: { color: "inherit" } }}>{LAN.EMPTY.text}</AdvText>
                </AdvMessageBar>
            );
        } else {
            return (
                <>
                    <Shimmer height={32} />
                    <Shimmer height={32} />
                </>
            );
        }
        return <></>;
    }, [dpIsEOF, errs, hasErr, tableData]);

    const hasEverLoaded = useRef<boolean>();

    const isLoading = useMemo(() => {
        const hasFinishedLoading =
            (dpIsLoaded() &&
                columns.widthOfAllColumns > 0 &&
                (tableData.items.length >= pageSize || dpIsEOF() || designerData != undefined)) ||
            hasEverLoaded.current === true;

        if (hasFinishedLoading) {
            hasEverLoaded.current = true;
        }

        return !hasFinishedLoading;
    }, [columns.widthOfAllColumns, designerData, dpIsEOF, dpIsLoaded, tableData.items.length]);

    /**
     * An effect that positions the row to a potential result value
     * from a previous call, if the table set a positioning definition
     */
    const setSelectionByPositioning = useAdvCallback(() => {
        if (!isLoading && dpIsEOF() && !didPosition.current) {
            if (dataPositioning != undefined && resultPageVar != undefined) {
                if (tableData.recordIndexPageCallResult != -1) {
                    didPosition.current = true;
                    if (
                        tableData.renderItems[tableData.recordIndexPageCallResult] != undefined &&
                        tableData.renderItems[tableData.recordIndexPageCallResult].kind == "item"
                    ) {
                        //falls es in den RenderItems eine rowId-DatensatzId-Zuordnung gibt
                        //dann auch die eventuellen zusaetzlich repraesentierten Datensaetze mitgeben
                        const selectedItemsArray = [
                            tableData.renderItems[tableData.recordIndexPageCallResult].indexOfKind,
                        ].concat(
                            tableData.renderItems[tableData.recordIndexPageCallResult]
                                .additionalRepresentedIOKs,
                        );
                        const uniqueSelectedItemsArray = selectedItemsArray.filter(
                            (value, index) => selectedItemsArray.indexOf(value) === index,
                        );
                        dpSetCurrentRecords(uniqueSelectedItemsArray);
                    } else {
                        dpSetCurrentRecords([tableData.recordIndexPageCallResult]);
                    }
                    return true;
                }
            }
        }
        return false;
    }, [
        dataPositioning,
        dpIsEOF,
        dpSetCurrentRecords,
        isLoading,
        resultPageVar,
        tableData.recordIndexPageCallResult,
        tableData.renderItems,
    ]);

    useAdvEffect(() => {
        if (!setSelectionByPositioning()) {
            const selectedValues = selections;
            let newDpSelections: Array<number> = [];
            selectedValues.forEach((val) => {
                if (
                    tableData.renderItems[val] != undefined &&
                    tableData.renderItems[val].kind == "item"
                ) {
                    newDpSelections.push(tableData.renderItems[val].indexOfKind);
                    //zusaetzlich von diesem renderItem repraesentierte Datensaetze
                    newDpSelections = newDpSelections.concat(
                        tableData.renderItems[val].additionalRepresentedIOKs,
                    );
                }
            });
            if (newDpSelections.length > 0) {
                if (!dpSetCurrentRecords(newDpSelections))
                    advlog("Couldnt set new records, dataprovider was not editable.");
            }
        }
    }, [dpSetCurrentRecords, selections, setSelectionByPositioning, tableData.renderItems]);

    // Checken ob current selection durch Provider geändert wurde, dann unsere selection anpassen
    useAdvEffect(() => {
        const selectedRows = (dpGetCurrentRecords() ?? []).map(
            (val) =>
                tableData.itemsToRenderItemsMapping[val[gDataproviderRecordIndexName] as number],
        );
        const uniqueSelectedRows = selectedRows.filter(
            (value, index) => selectedRows.indexOf(value) === index,
        );
        updateSelection(uniqueSelectedRows);
    }, [dpGetCurrentRecords, updateSelection, tableData.itemsToRenderItemsMapping]);

    // timeout cleanup
    useAdvEffect(() => {
        return () => {
            for (const timeOuts of pageLoadListTimeouts.current) {
                clearTimeout(timeOuts);
            }
            pageLoadListTimeouts.current = new Array<any>();
        };
    }, []);

    const [nameValue] = useAdvValueBinderNoDataType(
        nameBindingParams,
        name,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const breadcrumbPageName = useAdvRecoilValue(activePageName);

    const exportName = useMemo(() => {
        let res = "";

        if (typeof breadcrumbPageName == "string" && breadcrumbPageName != "") {
            res += breadcrumbPageName;
            if (typeof nameValue == "string" && nameValue != "") {
                res += "_";
            }
        }
        if (typeof nameValue == "string" && nameValue != "") {
            res += nameValue;
        }
        if (res == "") {
            res = nanoid();
        }

        return res;
    }, [breadcrumbPageName, nameValue]);

    const [canFilterValue] = useAdvValueBinderNoDataType(
        canFilterBindingParams,
        canFilter,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [canSortValue] = useAdvValueBinderNoDataType(
        canSortBindingParams,
        canSort,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const styles = useMemo(() => {
        let styles = propStyles;
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, propStyles, theme]);

    const spinnerProps = useMemo<TAdvSpinnerProps>(() => {
        return {
            label: "Lade Daten",
            size: EAdvSpinnerSize.large,
            labelPosition: "right",
            withDots: true,
        };
    }, []);
    const onSpinnerClick = useAdvCallback(() => {
        if (designerProps != undefined) {
            designerProps.onClick();
        }
    }, [designerProps]);

    type TSearchParam = { newSearchText: string | undefined; listOfFieldsToSearch: string[] };
    const dpSearchWrapper = useAdvCallback(
        (param: TSearchParam) => {
            dpSetSearchText(param.newSearchText, param.listOfFieldsToSearch);
        },
        [dpSetSearchText],
    );
    const { setterFunc: setSearchTextInternal } =
        useDebounceSetterFunc<TSearchParam>(dpSearchWrapper);
    const setSearchParams = useAdvCallback<typeof setSearchTextInternal>(
        (searchParams) => {
            if (!isInsideDesigner) setSearchTextInternal(searchParams);
        },
        [isInsideDesigner, setSearchTextInternal],
    );
    const setSearchText = useAdvCallback(
        (text: string | undefined) => {
            setSearchParams({
                newSearchText: text,
                listOfFieldsToSearch: columns.columns
                    .map((col) => col.definition.fieldName ?? "")
                    .filter((col) => col != ""),
            });
        },
        [columns, setSearchParams],
    );
    type TColumnSortSetter = (
        old: Array<TAdvCustomColumnsDefinition>,
    ) => Array<TAdvCustomColumnsDefinition>;
    const setSortColumns = useAdvCallback(
        (newCols: TAdvCustomColumnsDefinition[] | TColumnSortSetter) => {
            if (!isInsideDesigner) {
                dpSetFilterSort((old) => {
                    const oldColumns = old
                        .filter((valS) => {
                            return (
                                columns.columns.find(
                                    (valC) => valC.definition.fieldName == valS.name,
                                ) != undefined
                            );
                        })
                        .map<TAdvCustomColumnsDefinition>((valS) => {
                            const foundCol = columns.columns.find(
                                (valC) => valC.definition.fieldName == valS.name,
                            );
                            assert(
                                foundCol != undefined,
                                "Non existing columns should be filtered.",
                            );
                            return foundCol;
                        });
                    const newColumns =
                        typeof newCols == "function"
                            ? (newCols as TColumnSortSetter)(oldColumns)
                            : newCols;
                    const newColsRes = newColumns
                        .filter(
                            (valC) =>
                                valC.definition.fieldName != undefined &&
                                (old.find((o) => o.name === valC.definition.fieldName)?.isConst ??
                                    false) !== true,
                        )
                        .map<TDataProviderFilterSort>((valC) => {
                            const fieldName = valC.definition.fieldName;
                            assert(fieldName != undefined, "field name should be filtered.");
                            return {
                                name: fieldName,
                                desc: valC.definition.isSortedDescending ?? false,
                            };
                        });

                    const frontCols: TDataProviderFilterSort[] = [];
                    old.forEach((o) => {
                        if (o.isConst === true) {
                            // check if old sort is in the new sortings
                            const foundSort = newColsRes.find((n) => n.name == o.name);
                            assert(
                                foundSort == undefined,
                                "this column should have been filtered in a previous statement.",
                            );
                            // add it to the sorting to the front
                            frontCols.push(deepCopy(o));
                        }
                    });

                    newColsRes.splice(0, 0, ...frontCols);

                    if (JSON.stringify(newColsRes) != JSON.stringify(old)) {
                        return newColsRes;
                    }
                    return old;
                });
            }
        },
        [columns, dpSetFilterSort, isInsideDesigner],
    );

    const { showError } = useAdvToast({ message: "Kein Provider oder Spalten vorhanden." });

    /** Bei Klick auf eine Überschrift soll die entsprechende Spalte sortiert werden.*/
    const onColumnHeaderClick = useAdvCallback(
        function (
            ev?: React.MouseEvent<HTMLElement>,
            clickedColumn?: TAdvCustomColumnsDefinition,
        ): void {
            if (typeof ev == "undefined" || typeof clickedColumn == "undefined" || !canSortValue)
                return;

            if (clickedColumn.definition.crosstableValueSourcefield != undefined) {
                //wenn auf eine Ergebnisspalte aus der Kreuztabelle geklickt wird, soll erstmal nicht sortiert werden können
                showError("Nach dieser Spalte kann nicht sortiert werden.");
                return;
            }

            const isAppendMode = ev.ctrlKey || ev.shiftKey;
            setSortColumns((old) => {
                let newSortColumns = deepCopy(old, true);
                // if column exists, just change sorting
                const foundColumn = newSortColumns?.find(
                    (valS) =>
                        (valS.definition.isSorted ?? false) &&
                        valS.definition.key === clickedColumn?.definition.key,
                );
                if (foundColumn != undefined) {
                    foundColumn.definition.isSortedDescending = !(
                        foundColumn.definition.isSortedDescending ?? true
                    );
                    if (!isAppendMode) newSortColumns = [foundColumn];
                } else {
                    const newCol = deepCopy(clickedColumn, true);
                    newCol.definition.isSorted = true;
                    newCol.definition.isSortedDescending = false;
                    if (isAppendMode) newSortColumns.push(newCol);
                    else newSortColumns = [newCol];
                }
                return newSortColumns;
            });
        },
        [canSortValue, setSortColumns, showError],
    );

    const setFilterSelection = useAdvCallback<typeof dpSetFilterSelection>(
        (newFilterSel) => dpSetFilterSelection(newFilterSel),
        [dpSetFilterSelection],
    );
    const onDropTableCol = useAdvCallback(
        (dragDropDetails: IColumnDragDropDetails) => {
            if (
                !isInsideDesigner &&
                dragDropDetails.draggedIndex != columns.columns.length - 1 &&
                dragDropDetails.targetIndex != columns.columns.length - 1
            )
                setColumnIndices((old) => {
                    const dIndex = dragDropDetails.draggedIndex;
                    const tIndex = dragDropDetails.targetIndex;
                    if (columns.columns.length > dIndex && columns.columns.length > tIndex) {
                        const dName = columns.columns[dIndex].definition.fieldName;
                        if (dName != undefined) {
                            const colNames = columns.columns.map((col) => {
                                return col.definition.fieldName;
                            });
                            colNames.splice(dIndex, 1);
                            colNames.splice(tIndex, 0, dName);

                            const oldKeys = Object.keys(old);
                            const res: TTableColumnOrder = {};
                            colNames.forEach((val, index) => {
                                if (val != undefined && (oldKeys.includes(val) || val == dName))
                                    res[val] = index;
                            });
                            if (keyRef != undefined && keyRef != "")
                                cacheSet(gTableColOrderCacheKey + keyRef, res, 0).catch(advcatch);
                            return res;
                        }
                    }

                    return old;
                });
        },
        [columns, isInsideDesigner, keyRef],
    );

    const excelExportContentMenuUser = useRef<any>();
    const [isExcelExpContentMenuOpen, setIsExcelExpContentMenuOpen] = useState(false);

    const handleToggleCollapse = useAdvCallback((group: IGroup) => {
        // Gruppe wurde zu- bzw. aufgeklappt
        if (group.isCollapsed === undefined) return;
        if (group.isCollapsed) collapsedGroupsArray.current.push(group.key);
        else if (allGroupsCollapsed.current) allGroupsCollapsed.current = false;
        collapsedGroupsArray.current = collapsedGroupsArray.current.filter((x) => x != group.key);
    }, []);
    const handleToggleCollapseAll = useAdvCallback(
        (isAllCollapsed: boolean) => {
            // Alle Gruppen wurde zu- bzw. aufgeklappt
            if (isAllCollapsed) {
                dpLoadData(0, 1000);
            }
            allGroupsCollapsed.current = isAllCollapsed;
        },
        [dpLoadData],
    );

    const filterSelection = useMemo(() => dpGetFilterSelection(), [dpGetFilterSelection]);

    const filterSelections = useMemo<TAdvFilterSelection>(() => {
        const res: TAdvFilterSelection = {};
        if (filter != undefined) {
            const keysSel = filterSelection != undefined ? Object.keys(filterSelection) : [];
            filter.forEach((val) => {
                if (keysSel.includes(val.Name) && filterSelection != undefined) {
                    Object.assign(res, { ...res, [val.Name]: filterSelection[val.Name] });
                    return;
                }
                const defaultValIndex = val.Options.findIndex((valO) => valO.ActiveByDefault);
                if (defaultValIndex != -1)
                    Object.assign(res, { ...res, [val.Name]: defaultValIndex });
            });
        }
        return res;
    }, [filter, filterSelection]);

    const customFilterOptionsEl = useMemo(() => {
        let filterOptionKey = 1;
        if (pageSizeType > EPageComponentSizeType.Mobile) {
            return tableHeaderFilters
                .map((v) => {
                    return (
                        <AdvStackItem
                            align="end"
                            key={"filteroptions2stackthf" + String(filterOptionKey++)}
                        >
                            <AdvDropdown
                                key={"filteroptions2thf" + String(filterOptionKey++)}
                                translatableTextLabel={toAdvText(v.label ?? "")}
                                optionsData={v.optionsData}
                                optionsDataBindingParams={v.optionsDataBindingParams}
                                optionsText={v.optionsText}
                                optionsTextBindingParams={v.optionsTextBindingParams}
                                options={[]}
                                value={v.value}
                                valueBindingParams={v.valueBindingParams}
                                disabled={v.isDisabled}
                                disabledBindingParams={v.isDisabledBindingParams}
                                multiSelect={v.multiselect}
                                pageProps={pageProps}
                                styles={{ root: { width: 200, marginRight: 3 } }}
                            />
                        </AdvStackItem>
                    );
                })
                .concat(
                    filter?.map(({ Name, Options }) => {
                        const items: TAdvDropdownItem[] = Options.map((value, index) => {
                            return { key: `filter_cbo_${index}`, text: value.Name };
                        });

                        const curItem = Object.keys(filterSelections).includes(Name)
                            ? items[filterSelections[Name]]
                            : undefined;

                        return (
                            <AdvStackItem
                                align="end"
                                key={"filteroptions2stack" + String(filterOptionKey++)}
                            >
                                <AdvDropdown
                                    key={"filteroptions2" + String(filterOptionKey++)}
                                    onValueChanged={(newName) => {
                                        if (newName !== undefined) {
                                            const optionIndex = Options.findIndex((optionVal) => {
                                                return optionVal.Name == newName.text;
                                            });
                                            setFilterSelection((old) => {
                                                return { ...(old ?? {}), [Name]: optionIndex };
                                            });
                                        }
                                    }}
                                    options={items}
                                    value={curItem}
                                    translatableTextLabel={toAdvText(Name)}
                                    dropdownWidth={"auto"}
                                    responsiveMode={ResponsiveMode.large}
                                    styles={{ root: { width: 200, marginRight: 3 } }}
                                    pageProps={pageProps}
                                />
                            </AdvStackItem>
                        );
                    }),
                );
        } else {
            if (filter?.length === 0 && tableHeaderFilters.length === 0) return <></>;
            return (
                <AdvStackItem align="end" key={"filteroptions2menu" + String(filterOptionKey++)}>
                    <FluentProvider theme={createV9Theme(theme)}>
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button size="small" style={{ border: "none", minHeight: "32px" }}>
                                    <AdvIcon iconName={FilterIcon.iconName}></AdvIcon>
                                </Button>
                            </MenuTrigger>
                            <MenuPopover>
                                {filter?.map(({ Name, Options }) => {
                                    const items: TAdvDropdownItem[] = Options.map(
                                        (value, index) => {
                                            return { key: `filter_cbo_${index}`, text: value.Name };
                                        },
                                    );

                                    const curItem = Object.keys(filterSelections).includes(Name)
                                        ? items[filterSelections[Name]]
                                        : undefined;

                                    return (
                                        <MenuItem
                                            key={"filteroptions2menu" + String(filterOptionKey++)}
                                            persistOnClick
                                            className="adv-fui-MenuItem"
                                        >
                                            <AdvDropdown
                                                key={"filteroptions2" + String(filterOptionKey++)}
                                                onValueChanged={(newName) => {
                                                    if (newName !== undefined) {
                                                        const optionIndex = Options.findIndex(
                                                            (optionVal) => {
                                                                return (
                                                                    optionVal.Name == newName.text
                                                                );
                                                            },
                                                        );
                                                        setFilterSelection((old) => {
                                                            return {
                                                                ...(old ?? {}),
                                                                [Name]: optionIndex,
                                                            };
                                                        });
                                                    }
                                                }}
                                                options={items}
                                                value={curItem}
                                                translatableTextLabel={toAdvText(Name)}
                                                dropdownWidth={"auto"}
                                                responsiveMode={ResponsiveMode.large}
                                                styles={{ root: { width: 200, marginRight: 3 } }}
                                                pageProps={pageProps}
                                            />
                                        </MenuItem>
                                    );
                                })}
                                {tableHeaderFilters.map((v) => {
                                    return (
                                        <MenuItem
                                            key={"filteroptions2menu" + String(filterOptionKey++)}
                                            persistOnClick
                                            className="adv-fui-MenuItem"
                                        >
                                            <AdvDropdown
                                                key={"filteroptions2" + String(filterOptionKey++)}
                                                translatableTextLabel={toAdvText("TODO:")}
                                                optionsData={v.optionsData}
                                                optionsDataBindingParams={
                                                    v.optionsDataBindingParams
                                                }
                                                optionsText={v.optionsText}
                                                optionsTextBindingParams={
                                                    v.optionsTextBindingParams
                                                }
                                                options={[]}
                                                value={v.value}
                                                valueBindingParams={v.valueBindingParams}
                                                disabled={v.isDisabled}
                                                disabledBindingParams={v.isDisabledBindingParams}
                                                multiSelect={v.multiselect}
                                                pageProps={pageProps}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </MenuPopover>
                        </Menu>
                    </FluentProvider>
                </AdvStackItem>
            );
        }
    }, [
        filter,
        filterSelections,
        pageProps,
        pageSizeType,
        setFilterSelection,
        tableHeaderFilters,
        theme,
    ]);

    const searchText = useMemo(() => dpGetSearchText(), [dpGetSearchText]);

    const filterSearchEl = useMemo(() => {
        return canFilterValue ? (
            <AdvStackItem
                align="end"
                grow={isMobile ? 1 : 0}
                styles={{ root: { display: isMobile ? "flex" : undefined } }}
            >
                <AdvSearchInput
                    onValueChanged={setSearchText}
                    value={searchText}
                    placeholder={LAN.SEARCH_DOT_DOT_DOT.text}
                    styles={{ root: { marginTop: 4, flexGrow: isMobile ? 1 : 0 } }}
                />
            </AdvStackItem>
        ) : (
            <></>
        );
    }, [canFilterValue, isMobile, searchText, setSearchText]);

    const tableLeftHeaderElement = useMemo(() => {
        return (
            <FluentProvider theme={createV9Theme(theme)}>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button size="small" style={{ border: "none", minHeight: "32px" }}>
                            <AdvIcon iconName={BarsIcon.iconName}></AdvIcon>
                        </Button>
                    </MenuTrigger>
                    <MenuPopover>
                        {pageSizeType <= EPageComponentSizeType.Mobile &&
                            tableHeaderActions.map((a, index) => {
                                return (
                                    <MenuItem
                                        key={"__tabActHeaderMenu" + index.toString()}
                                        className="adv-fui-MenuItem"
                                    >
                                        <AdvButton
                                            webActionParams={a.action}
                                            keyRef={keyRef ?? ""}
                                            simplified
                                            flexGrow
                                            disabled={a.isDisabled}
                                            disabledBindingParams={a.isDisabledBindingParams}
                                            styles={{
                                                textContainer: {
                                                    textAlign: "left",
                                                },
                                            }}
                                        ></AdvButton>
                                    </MenuItem>
                                );
                            })}
                        <Divider></Divider>
                        {canExcelExport ? (
                            <MenuItem className="adv-fui-MenuItem">
                                <AdvButton
                                    text="Excel"
                                    iconName={FileExcel.iconName}
                                    disabled={
                                        dpIsEOF() &&
                                        (tableData.items.length == 0 || tableData.items[0] == null)
                                    }
                                    simplified
                                    onClick={() => {
                                        if (designerData === undefined) {
                                            // Falls Gruppierug vorhanden: Spalte für die Gruppierung hinzufügen
                                            if (
                                                columns.crosslabelField != undefined &&
                                                columns.crossvalueFields != undefined &&
                                                columns.crossvalueFields.length > 0
                                            ) {
                                                //wir haben eine Kreuztabelle, das heisst der DataProvider kann uns nicht helfen
                                                //wir geben die gerenderten Daten selbst direkt zur Excelerstellung
                                                const colSize: Array<number> = [];
                                                const data: any[][] = [];
                                                columns.columns.forEach(
                                                    (aColumn: TAdvCustomColumnsDefinition) => {
                                                        if (
                                                            aColumn.definition.name == "-" ||
                                                            aColumn.definition.name == ""
                                                        )
                                                            return; //leere Spalten ueberspringen
                                                        let curColSize =
                                                            aColumn.definition.name.length;
                                                        data.push([
                                                            aColumn.definition.name,
                                                            ...tableData.renderItems.map(
                                                                //renderItems enthaelt kind und id
                                                                (anItem: TGroupedItem) => {
                                                                    let cellVal = "";
                                                                    if (
                                                                        anItem.kind == "group" ||
                                                                        anItem.kind ==
                                                                            "group_waypoint"
                                                                    ) {
                                                                        cellVal =
                                                                            tableData.groups[
                                                                                anItem.indexOfKind
                                                                            ].name;
                                                                    } else if (
                                                                        anItem.kind == "item" ||
                                                                        anItem.kind ==
                                                                            "item_waypoint"
                                                                    ) {
                                                                        //tableData.items enthalten die richtigen Inhalte
                                                                        const itemData =
                                                                            tableData.items[
                                                                                anItem.indexOfKind
                                                                            ];
                                                                        if (itemData != undefined) {
                                                                            cellVal =
                                                                                itemData[
                                                                                    aColumn
                                                                                        .definition
                                                                                        .fieldName
                                                                                ];
                                                                        }
                                                                    }
                                                                    if (
                                                                        cellVal !== undefined &&
                                                                        cellVal.length !== undefined
                                                                    ) {
                                                                        curColSize = Math.max(
                                                                            curColSize,
                                                                            cellVal.length,
                                                                        );
                                                                    }
                                                                    return cellVal;
                                                                },
                                                            ),
                                                        ]);
                                                        colSize.push(curColSize);
                                                    },
                                                );
                                                createExcelFile(
                                                    exportName,
                                                    exportName,
                                                    data,
                                                    colSize,
                                                );
                                            } else {
                                                const exportData: TExcelExportData = {
                                                    fieldNames: [],
                                                    fileName: exportName,
                                                    sheetName:
                                                        typeof nameValue == "string" &&
                                                        nameValue != "" &&
                                                        nameValue.length < 31
                                                            ? nameValue
                                                            : "Sheet 1",
                                                };

                                                // Falls Gruppierug vorhanden: Spalte für die Gruppierung hinzufügen
                                                if (
                                                    autoGrouping !== undefined &&
                                                    autoGrouping.groupByField !== undefined
                                                ) {
                                                    exportData.fieldNames.push({
                                                        FieldName: autoGrouping.groupByField,
                                                        DisplayName:
                                                            autoGrouping.groupName ??
                                                            autoGrouping.groupByField,
                                                    });
                                                }
                                                exportData.fieldNames.push(
                                                    ...columns.columns
                                                        .filter(
                                                            (c) =>
                                                                c.definition.fieldName ?? "" != "",
                                                        )
                                                        .map((c) => {
                                                            return {
                                                                FieldName:
                                                                    c.definition.fieldName ?? "",
                                                                DisplayName: c.definition.name,
                                                            };
                                                        }),
                                                );
                                                dpDownloadExcel(exportData);
                                            }
                                        }
                                    }}
                                    flexGrow
                                    styles={{
                                        textContainer: {
                                            textAlign: "left",
                                        },
                                    }}
                                ></AdvButton>
                            </MenuItem>
                        ) : (
                            <></>
                        )}
                        <MenuItem className="adv-fui-MenuItem">
                            <AdvButton
                                text={LAN.RELOAD.text}
                                iconName={RefreshIcon.iconName}
                                simplified
                                onClick={() => {
                                    setSearchText("");
                                    dpReload();
                                }}
                                flexGrow
                                styles={{
                                    textContainer: {
                                        textAlign: "left",
                                    },
                                }}
                            ></AdvButton>
                        </MenuItem>
                        <MenuItem className="adv-fui-MenuItem">
                            <AdvButton
                                text={LAN.RESET_TABLE_VIEW.text}
                                iconName={FilterResetIcon.iconName}
                                simplified
                                onClick={() => {
                                    dpResetSort();
                                }}
                                styles={{
                                    textContainer: {
                                        textAlign: "left",
                                    },
                                }}
                                flexGrow
                            ></AdvButton>
                        </MenuItem>
                    </MenuPopover>
                </Menu>
            </FluentProvider>
        );
    }, [
        theme,
        pageSizeType,
        tableHeaderActions,
        canExcelExport,
        dpIsEOF,
        tableData.items,
        keyRef,
        designerData,
        exportName,
        autoGrouping,
        columns.columns,
        dpDownloadExcel,
        setSearchText,
        dpReload,
        dpResetSort,
        columns.crosslabelField,
        columns.crossvalueFields,
        tableData.groups,
        tableData.renderItems,
        nameValue,
    ]);

    const tableHeader = useMemo(() => {
        return (
            <AdvStack
                horizontalAlign={isMobile ? "start" : "space-between"}
                horizontal={true}
                tokens={{ childrenGap: 0 }}
                styles={{ root: { width: "100%" } }}
                grow
            >
                <AdvTableActionHeader
                    tableHeaderActions={tableHeaderActions}
                    tableLeftHeaderElement={tableLeftHeaderElement}
                    uniqueKey={itemUniqueness}
                    keyRef={keyRef ?? ""}
                    designerData={designerData}
                    designerProps={designerProps}
                    defaultActionName={defaultActionParams?.actionName}
                    pageLayout={pageSizeType}
                ></AdvTableActionHeader>
                <AdvStack
                    horizontalAlign="end"
                    horizontal={true}
                    grow={isMobile ? 1 : 0}
                    wrap
                    tokens={{ childrenGap: 0 }}
                    styles={{
                        inner: { alignContent: "start" },
                        root: { flexGrow: isMobile ? 1 : 0 },
                    }}
                >
                    {pageSizeType > EPageComponentSizeType.Mobile ? (
                        <>
                            {customFilterOptionsEl}
                            {filterSearchEl}
                        </>
                    ) : (
                        <>
                            {filterSearchEl}
                            {customFilterOptionsEl}
                        </>
                    )}
                </AdvStack>
            </AdvStack>
        );
    }, [
        customFilterOptionsEl,
        defaultActionParams?.actionName,
        designerData,
        designerProps,
        filterSearchEl,
        isMobile,
        itemUniqueness,
        keyRef,
        pageSizeType,
        tableHeaderActions,
        tableLeftHeaderElement,
    ]);

    const [shouldExecAction, setShouldExecAction] = useState<TSetExecAction>(undefined);

    const [shouldDoDblClick, setShouldDoDblClick] = useState(false);
    const handleRowDblClick = useAdvCallback(() => {
        setTimeout(() => {
            setShouldDoDblClick(true);
        }, 1);
    }, [setShouldDoDblClick]);

    const [storedValues, setStoredValues] = useState<Record<string, string>>({});

    const renderRow: RowRenderer<TGroupedItem> = useAdvCallback(
        ({ rowId, item }, style) => {
            let inner = undefined;
            let isWaypoint = false;
            if (item.kind == "group" || item.kind == "group_waypoint") {
                isWaypoint = item.kind == "group_waypoint";
                if (tableData.groups.length <= item.indexOfKind) inner = <></>;
                else
                    inner = (
                        <div
                            key={rowId}
                            style={{ ...style, display: "flex", alignItems: "center" }}
                        >
                            {tableData.groups[item.indexOfKind].name}
                        </div>
                    );
            } else if (item.kind == "null" || item.kind == "null_waypoint") {
                isWaypoint = item.kind == "null_waypoint";
                inner = (
                    <div key={rowId} style={style}>
                        {renderNullItem()}
                    </div>
                );
            } else if (item.kind == "item" || item.kind == "item_waypoint") {
                isWaypoint = item.kind == "item_waypoint";
                inner = (
                    <DataGridRow
                        key={rowId}
                        style={style}
                        onDoubleClick={handleRowDblClick}
                        selectionCell={
                            selectionMode == SelectionMode.multiple
                                ? undefined
                                : {
                                      style: { width: 0, maxWidth: 0, minWidth: 0 },
                                  }
                        }
                    >
                        {({ columnId }) => {
                            const col = columns.columns[columnId as number];
                            const fieldName = col?.definition.fieldName;
                            const itemData = tableData.items[item.indexOfKind];

                            let cellVal = undefined;
                            let isForceHidden = false;
                            const pageLayout =
                                pageProps?.sizeType ?? EPageComponentSizeType.DesktopWide;
                            let action: TAdvWebActionParams | undefined;

                            if (
                                col?.definition.action != undefined &&
                                col?.definition.action.actionName != ""
                            ) {
                                action = col?.definition.action;
                            }

                            let rawCellData: any;

                            if (fieldName != undefined) {
                                let fieldType: EFieldSettingsFieldTypes =
                                    EFieldSettingsFieldTypes.default;
                                let fieldDomain: EFieldSettingsFieldDomain =
                                    EFieldSettingsFieldDomain.unknown;
                                isForceHidden = false;

                                if (col?.field != null) {
                                    if (col?.definition.crosstableValueSourcefield != undefined) {
                                        //der Fall Kreuztabelle: unsere Spalte stammt aus der Erstellung einer Kreuztabelle, es gibt also ein zugehoeriges Feld (das ist aber nicht der Spalten-fieldname)
                                        fieldType = col?.field.Settings.FieldType;
                                        fieldDomain = col?.field.Settings.FieldDomain;
                                        isForceHidden = col?.field.Properties.ForceHidden;
                                    } else {
                                        //der Standardfall: unsere Spalte stammt aus der Datenbank es gibt also ein zugehoeriges Feld
                                        fieldType = col?.field.Settings.FieldType;
                                        fieldDomain = col?.field.Settings.FieldDomain;
                                        isForceHidden = col?.field.Properties.ForceHidden;
                                    }
                                }

                                if (
                                    itemData != undefined &&
                                    typeof columns.columns != "undefined" &&
                                    (columnId as number) < columns.columns.length &&
                                    typeof columns.columns[columnId as number] != "undefined" &&
                                    typeof columns.columns[columnId as number].definition !=
                                        "undefined" &&
                                    typeof itemData[
                                        columns.columns[columnId as number].definition.fieldName
                                    ] != "undefined"
                                ) {
                                    cellVal = (
                                        <AdvCellValue
                                            type={fieldType}
                                            domain={fieldDomain}
                                            value={itemData[fieldName]}
                                            curSearchText={dpGetSearchText()}
                                        />
                                    );

                                    rawCellData = itemData[fieldName];
                                }
                            }

                            const storeKey =
                                columnId.toString() + "_" + item.indexOfKind.toString();

                            return (
                                <AdvDataGridCell
                                    key={"row" + columnId.toString()}
                                    cellId={
                                        "row" + columnId.toString() + "_" + JSON.stringify(itemData)
                                    }
                                    bindings={col}
                                    forceHide={isForceHidden}
                                    pageLayout={pageLayout}
                                    action={action}
                                    actionType={col?.definition.actionType}
                                    keyRef={keyRef}
                                    rawCellValue={storedValues[storeKey] ?? rawCellData}
                                    rowID={item.indexOfKind}
                                    setSelection={setSelectionsWrapper}
                                    setShouldExecAction={setShouldExecAction}
                                    storeValue={(val) => {
                                        setStoredValues((oldVal) => {
                                            oldVal[storeKey] = val;

                                            return oldVal;
                                        });
                                    }}
                                >
                                    {isMobile && col?.definition.key == "empty_back" ? (
                                        <AdvEndlessTableMobileModal
                                            itemData={itemData}
                                            columns={columns.columns}
                                        ></AdvEndlessTableMobileModal>
                                    ) : itemData != undefined &&
                                      typeof columns.columns != "undefined" &&
                                      (columnId as number) < columns.columns.length &&
                                      typeof columns.columns[columnId as number] != "undefined" &&
                                      typeof columns.columns[columnId as number].definition !=
                                          "undefined" ? (
                                        typeof itemData[
                                            columns.columns[columnId as number].definition.fieldName
                                        ] != "undefined" && cellVal
                                    ) : (
                                        <></>
                                    )}
                                </AdvDataGridCell>
                            );
                        }}
                    </DataGridRow>
                );
            }
            if (isWaypoint) {
                return (
                    <Waypoint
                        fireOnRapidScroll
                        onEnter={() => onWaypointEnter(tableData.items.length)}
                    >
                        {inner}
                    </Waypoint>
                );
            }
            return inner;
        },
        [
            columns.columns,
            dpGetSearchText,
            handleRowDblClick,
            isMobile,
            keyRef,
            onWaypointEnter,
            pageProps?.sizeType,
            renderNullItem,
            selectionMode,
            setSelectionsWrapper,
            storedValues,
            tableData.groups,
            tableData.items,
        ],
    );

    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });

    const columnSizingOptions = useMemo(() => {
        let res: Record<
            number,
            {
                minWidth?: number;
                defaultWidth?: number;
                idealWidth?: number;
            }
        > = {};
        columns.columns.forEach((val, colIndex) => {
            res = {
                ...res,
                [colIndex]: {
                    defaultWidth: val.definition.idealWidth,
                    idealWidth: val.definition.idealWidth,
                    minWidth: val.definition.idealWidth,
                },
            };
        });
        return res;
    }, [columns]);

    const v9Theme = useMemo(() => {
        return createV9Theme(theme);
    }, [theme]);

    const tableRef: Ref<any> = useRef(null);
    const didScroll = useRef(false);
    useAdvEffect(() => {
        if (
            !isLoading &&
            tableRef.current != undefined &&
            tableData.recordIndexPageCallResult != -1
        ) {
            didScroll.current = true;
            tableRef.current.scrollToItem(tableData.recordIndexPageCallResult);
        }
    });

    const [, , colAction, , , isDisabled] = useAdvWebAction(
        shouldExecAction?.user ?? "",
        shouldExecAction?.userIndex ?? 0,
        shouldExecAction?.params,
    );

    useAdvEffect(() => {
        if (shouldExecAction != undefined && !isDisabled) {
            setShouldExecAction(undefined);
            colAction();
        }
    }, [colAction, isDisabled, shouldExecAction]);

    const tabComp = useMemo(() => {
        return (
            <AdvStack verticalFill style={{ overflowX: "hidden" }}>
                <ContentBoxCreator calculatedColumnSize={columns.widthOfAllColumns}>
                    {() => (
                        <AdvStack verticalFill style={{ overflowX: "hidden" }}>
                            <FluentProvider
                                theme={v9Theme}
                                style={{ flexGrow: 1, overflowX: "hidden" }}
                            >
                                {shouldDoDblClick && (
                                    <DblClicker
                                        keyRef={keyRef}
                                        dataArrayIndex={dataArrayIndex}
                                        defaultActionParams={defaultActionParams}
                                        setDoDblClick={setShouldDoDblClick}
                                        dpSetCurrentRecords={dpSetCurrentRecords}
                                        selections={selections}
                                    ></DblClicker>
                                )}
                                <DataGrid
                                    items={tableData.renderItems}
                                    columns={columns.columns.map((col, index) => {
                                        return createTableColumn({
                                            columnId: index,
                                            // without the sorting function,
                                            // the table header cells will not have any hover effects
                                            // this also includes the unsued params :o
                                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                            compare: (a, b) => {
                                                // since we sort ourself, simply act as if the items are sorted
                                                return 0;
                                            },
                                            renderHeaderCell: () => {
                                                return col.definition.name;
                                            },
                                        });
                                    })}
                                    sortable={canSortValue}
                                    resizableColumns={!isInsideDesigner}
                                    onDrag={(e) => {
                                        e.preventDefault();
                                    }}
                                    draggable={false}
                                    focusMode="none"
                                    selectionMode={
                                        selectionMode == SelectionMode.single
                                            ? "single"
                                            : selectionMode == SelectionMode.multiple
                                            ? "multiselect"
                                            : undefined /* none */
                                    }
                                    selectionAppearance="neutral"
                                    subtleSelection
                                    columnSizingOptions={columnSizingOptions}
                                    style={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        overflowX: "hidden",
                                    }}
                                    selectedItems={selections}
                                    onSelectionChange={(ev, data) =>
                                        setSelectionsWrapper(data.selectedItems)
                                    }
                                >
                                    <DataGridHeader
                                        style={{
                                            paddingRight: scrollbarWidth,
                                            flexShrink: 0,
                                            flexGrow: 0,
                                        }}
                                    >
                                        <DataGridRow
                                            selectionCell={
                                                selectionMode == SelectionMode.multiple
                                                    ? undefined
                                                    : {
                                                          style: {
                                                              width: 0,
                                                              maxWidth: 0,
                                                              minWidth: 0,
                                                          },
                                                      }
                                            }
                                        >
                                            {({ renderHeaderCell, columnId }) => {
                                                const col = columns.columns[columnId as number];
                                                const isNullCol =
                                                    col != undefined &&
                                                    col.definition.fieldName == "" &&
                                                    col.definition.name == "";

                                                let isForceHidden = false;
                                                if (dpIsLoaded()) {
                                                    const fields = dpGetFields();
                                                    const fieldName = col?.definition.fieldName;
                                                    if (
                                                        fields != undefined &&
                                                        fieldName != undefined &&
                                                        typeof fields[fieldName] != "undefined"
                                                    ) {
                                                        isForceHidden =
                                                            fields[fieldName].Properties
                                                                .ForceHidden;
                                                    }
                                                }
                                                return (
                                                    <AdvDataGridHeaderCell
                                                        key={columnId}
                                                        bindings={col}
                                                        onClick={(ev) => {
                                                            if (!isNullCol)
                                                                onColumnHeaderClick(
                                                                    ev,
                                                                    columns.columns[
                                                                        columnId as number
                                                                    ],
                                                                );
                                                        }}
                                                        sortDirection={
                                                            columns.columns[columnId as number] ==
                                                                undefined ||
                                                            columns.columns[columnId as number]
                                                                .definition.isSortedDescending ==
                                                                undefined ||
                                                            (columns.columns[columnId as number]
                                                                .definition.isSorted ?? false) ===
                                                                false
                                                                ? undefined
                                                                : columns.columns[
                                                                      columnId as number
                                                                  ].definition
                                                                      .isSortedDescending === true
                                                                ? "descending"
                                                                : "ascending"
                                                        }
                                                        forceHide={isForceHidden}
                                                        pageLayout={
                                                            pageProps?.sizeType ??
                                                            EPageComponentSizeType.DesktopWide
                                                        }
                                                    >
                                                        {renderHeaderCell()}
                                                    </AdvDataGridHeaderCell>
                                                );
                                            }}
                                        </DataGridRow>
                                    </DataGridHeader>
                                    <ResizerFlex>
                                        {(height) => (
                                            <DataGridBody
                                                height={height}
                                                itemSize={50}
                                                listProps={{ ref: tableRef } as any}
                                                style={{ overflowX: "hidden" }}
                                            >
                                                {renderRow}
                                            </DataGridBody>
                                        )}
                                    </ResizerFlex>
                                </DataGrid>
                            </FluentProvider>
                            <AdvContextualMenu
                                items={[
                                    {
                                        key: "excel_export",
                                        text: "Excel",
                                        iconProps: {
                                            iconName: FileExcel.iconName,
                                        },
                                        onClick: () => {
                                            if (designerData === undefined) {
                                                dpDownloadExcel({
                                                    fieldNames: columns.columns
                                                        .filter(
                                                            (c) =>
                                                                c.definition.fieldName ?? "" != "",
                                                        )
                                                        .map((c) => {
                                                            return {
                                                                FieldName:
                                                                    c.definition.fieldName ?? "",
                                                                DisplayName: c.definition.name,
                                                            };
                                                        }),
                                                    fileName: nameValue,
                                                    sheetName:
                                                        typeof nameValue == "string" &&
                                                        nameValue != "" &&
                                                        nameValue.length < 31
                                                            ? nameValue
                                                            : "Sheet 1",
                                                });
                                            }
                                        },
                                    },
                                ]}
                                hidden={!isExcelExpContentMenuOpen}
                                target={excelExportContentMenuUser.current}
                                onItemClick={() => setIsExcelExpContentMenuOpen(false)}
                                onDismiss={() => setIsExcelExpContentMenuOpen(false)}
                            />
                        </AdvStack>
                    )}
                </ContentBoxCreator>
            </AdvStack>
        );
    }, [
        columns.widthOfAllColumns,
        columns.columns,
        v9Theme,
        shouldDoDblClick,
        keyRef,
        dataArrayIndex,
        defaultActionParams,
        dpSetCurrentRecords,
        selections,
        tableData.renderItems,
        canSortValue,
        isInsideDesigner,
        selectionMode,
        columnSizingOptions,
        scrollbarWidth,
        isExcelExpContentMenuOpen,
        setSelectionsWrapper,
        dpIsLoaded,
        pageProps?.sizeType,
        dpGetFields,
        onColumnHeaderClick,
        renderRow,
        designerData,
        dpDownloadExcel,
        nameValue,
    ]);

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    if (shouldHide === true && designerProps === undefined) return <></>;
    return (
        <AdvLoading
            isLoading={isLoading}
            mode={EAdvLoadingMode.HideContentWhenLoading}
            spinnerProps={spinnerProps}
            onClick={onSpinnerClick}
        >
            <AdvStack
                verticalFill
                onContextMenu={(ev: any) => {
                    excelExportContentMenuUser.current = ev.target;
                    // TODO: setIsExcelExpContentMenuOpen(true);
                    // ev.preventDefault();
                }}
                styles={{
                    root: {
                        ...((styles as any)?.root ?? {}),
                        backgroundColor: theme.palette.white,
                        overflowX: "hidden",
                    },
                }}
            >
                <AdvStackItem shrink={0}>{tableHeader}</AdvStackItem>
                <AdvStackItem grow shrink={0} style={{ overflowX: "hidden" }}>
                    {tabComp}
                </AdvStackItem>
            </AdvStack>
        </AdvLoading>
    );
};

const AdvEndlessTableNew = React.memo(AdvEndlessTableComp, deepCompareJSXProps);
export default AdvEndlessTableNew;
