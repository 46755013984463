import AdvButton from "@components/inputs/button-new/button";
import { TAdvButtonProps } from "@components/inputs/button-new/button-pure";
import AdvStack from "@components/layout/stack/stack";
import { EPageComponentSizeType } from "@components/page-component";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import {
    FluentProvider,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowItem,
    OverflowItemProps,
    makeStyles,
    shorthands,
    useIsOverflowItemVisible,
    useOverflowMenu,
} from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import { TAdvValueBindingParams } from "@hooks/dynamic/useAdvValueBinder";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import useAdvTheme from "@hooks/useAdvTheme";
import React from "react";
import { TAdvEndlessTableHeaderAction } from "./types";

const AdvButtonTable = React.forwardRef(
    (
        {
            webActionParams,
            keyRef,
            designerData,
            designerProps,
            isDefault = false,
            isDisabled,
            isDisabledBindingParams,
            isHidden,
            isHiddenBindingParams,
        }: {
            webActionParams: TAdvWebActionParams;
            isDisabled?: boolean;
            isDisabledBindingParams?: TAdvValueBindingParams;
            isHidden?: boolean;
            isHiddenBindingParams?: TAdvValueBindingParams;
            keyRef: string;
            isDefault?: boolean;
        } & TAdvDesignerComponentProps,
        ref: any,
    ) => {
        return (
            <div ref={ref}>
                <AdvButton
                    webActionParams={webActionParams}
                    simplified
                    keyRef={keyRef}
                    designerData={designerData}
                    designerProps={designerProps}
                    disabled={isDisabled}
                    advhide={isHidden}
                    advhideBindingParams={isHiddenBindingParams}
                    disabledBindingParams={isDisabledBindingParams}
                    style={{
                        textDecoration: isDefault ? "underline" : undefined,
                        marginLeft: "3px",
                        minHeight: "32px",
                    }}
                ></AdvButton>
            </div>
        );
    },
);
AdvButtonTable.displayName = "AdvButtonTable";

const OverflowMenuItem: React.FC<
    Pick<OverflowItemProps, "id"> & {
        webaction: TAdvWebActionParams;
        keyRef: string;
        isDefault?: boolean;
    } & TAdvDesignerComponentProps &
        TAdvButtonProps
> = ({ id, webaction, keyRef, designerData, designerProps, isDefault = false, ...props }) => {
    const isVisible = useIsOverflowItemVisible(id);

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem className="adv-fui-MenuItem">
            <AdvButton
                webActionParams={webaction}
                keyRef={keyRef}
                designerData={designerData}
                designerProps={designerProps}
                simplified
                style={
                    isDefault
                        ? {
                              textDecoration: "underline",
                              textAlign: "left",
                              justifyContent: "start",
                          }
                        : {
                              textAlign: "left",
                              justifyContent: "start",
                          }
                }
                {...props}
                flexGrow
            ></AdvButton>
        </MenuItem>
    );
};

const OverflowMenu: React.FC<
    {
        itemIds: string[];
        tableHeaderActions: TAdvEndlessTableHeaderAction[];
        keyRef: string;
        defaultActionName?: string;
    } & TAdvDesignerComponentProps
> = ({ itemIds, tableHeaderActions, keyRef, designerData, designerProps, defaultActionName }) => {
    const { ref, overflowCount, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    const theme = useAdvTheme();

    if (!isOverflowing) {
        return null;
    }

    return (
        <FluentProvider theme={createV9Theme(theme)}>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton
                        size="small"
                        ref={ref}
                        style={{ border: "none", minHeight: "32px" }}
                    >
                        {" (" + overflowCount.toString() + ") "}
                        ...{" "}
                    </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        {itemIds.map((i, index) => {
                            return (
                                <OverflowMenuItem
                                    key={i}
                                    id={i}
                                    webaction={tableHeaderActions[index].action}
                                    keyRef={keyRef}
                                    designerData={designerData}
                                    designerProps={designerProps}
                                    isDefault={
                                        defaultActionName ==
                                        tableHeaderActions[index].action.actionName
                                    }
                                    disabled={tableHeaderActions[index].isDisabled}
                                    disabledBindingParams={
                                        tableHeaderActions[index].isDisabledBindingParams
                                    }
                                />
                            );
                        })}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </FluentProvider>
    );
};

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "nowrap",
        minWidth: 0,
        ...shorthands.overflow("hidden"),
    },
});

export const AdvTableActionHeader = ({
    uniqueKey,
    tableLeftHeaderElement,
    tableHeaderActions,
    keyRef,
    designerData,
    designerProps,
    defaultActionName,
    pageLayout,
}: {
    uniqueKey: string;
    tableLeftHeaderElement: React.JSX.Element;
    tableHeaderActions: TAdvEndlessTableHeaderAction[];
    keyRef: string;
    defaultActionName?: string;
    pageLayout: EPageComponentSizeType;
} & TAdvDesignerComponentProps) => {
    const styles = useStyles();
    const isMobile = pageLayout <= EPageComponentSizeType.Mobile;
    return (
        <AdvStack
            horizontalAlign="start"
            horizontal={true}
            grow={isMobile ? 0 : 1}
            tokens={{ childrenGap: 0 }}
            styles={{ inner: { alignItems: "end" }, root: { alignItems: "end" } }}
        >
            {tableLeftHeaderElement}
            {!isMobile && (
                <Overflow>
                    <div className={styles.container}>
                        {tableHeaderActions.map((val, index) => {
                            return (
                                <OverflowItem
                                    key={uniqueKey + "__act" + index.toString()}
                                    id={index.toString()}
                                >
                                    <AdvButtonTable
                                        webActionParams={val.action}
                                        keyRef={keyRef}
                                        designerData={designerData}
                                        designerProps={designerProps}
                                        isDefault={defaultActionName == val.action.actionName}
                                        isDisabled={val.isDisabled}
                                        isDisabledBindingParams={val.isDisabledBindingParams}
                                        isHidden={val.isHidden}
                                        isHiddenBindingParams={val.isHiddenBindingParams}
                                    ></AdvButtonTable>
                                </OverflowItem>
                            );
                        })}
                        <OverflowMenu
                            itemIds={tableHeaderActions.map((_, index) => index.toString())}
                            tableHeaderActions={tableHeaderActions}
                            keyRef={keyRef}
                            designerData={designerData}
                            designerProps={designerProps}
                            defaultActionName={defaultActionName}
                        />
                    </div>
                </Overflow>
            )}
        </AdvStack>
    );
};
