import { TAdvCommonProperties } from "@components/other/common-properties";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { TAdvValueBindingParams } from "@hooks/dynamic/useAdvValueBinder";
import { DragEventHandler, Key, MouseEventHandler, ReactElement } from "react";

import { TPageComponentProps } from "@components/page-component";
import { IStackItemProps } from "@fluentui/react";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { TAdvGridItemStyles } from "../grid/grid-item/types";
import { TAdvGridProps, TAdvGridStyles } from "../grid/types";

export type TAdvGroupboxStyles = {
    /** aka container */
    root?: TAdvGridStyles;

    headerContainer?: TAdvGridItemStyles;
    contentContainer?: TAdvGridItemStyles;

    // headerButton?: TAdvButtonStyles;
    // headerText?: TAdvTextStyles;
};

export enum EAdvGroupboxCollapseDir {
    Top = 0,
    Bottom,
    Left,
    Right,
}

export type TAdvGroupboxProps = Omit<IStackItemProps, "styles"> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        /** Überschrift */
        heading: string;
        /** beliebige Zusatzinfos die bei eingeklappter Box angezeigt werden */
        additionalHeading?: string;

        ignoreTranslation?: boolean;
        pageProps?: TPageComponentProps;
        /** Inhalt der GroupBox */
        children: React.ReactNode | React.ReactNode[];
        /**
         * Ist die GroupBox geöffnet?
         * @important Wenn ein State genutzt wird, dann auch ``Key`` angeben!
         * @important Wenn ein State genutzt wird, dann auch ``onStateChange`` nutzen!
         */
        isOpen?: boolean;
        /** Wird aufgerufen, wenn sich der interne State (open) ändert. */
        onStateChange?: (open: boolean) => void;
        key?: Key;
        keyRef?: string;
        ignoreCache?: boolean;

        /** Soll die (klickbare) Überschrift sichtbar sein? */
        showHeading?: boolean;
        /** Soll die Groupbox einklappbar sein? Abhängig davon wird ein Button oder Text als Überschrift genutzt. */
        canCollapse?: boolean;
        /* In welche Richtung soll die Groupbox collapsen */
        collapseDir?: EAdvGroupboxCollapseDir;
        /** Soll ein Border angezeigt werden? Standard: TRUE */
        withBorder?: boolean;

        styles?: Partial<TAdvGroupboxStyles>;

        actionBar?: ReactElement;
        gridProps?: Partial<TAdvGridProps>;

        // Events die u.a. für Drag&Drop wichtig sind. Geben wir an den Content-Part weiter.
        onDragStart?: DragEventHandler<HTMLElement>;
        onDragOver?: DragEventHandler<HTMLElement>;
        onDragEnter?: DragEventHandler<HTMLElement>;
        onDragLeave?: DragEventHandler<HTMLElement>;
        onDrop?: DragEventHandler<HTMLElement>;

        onClick?: MouseEventHandler<HTMLDivElement>;

        headingBindingParams?: TAdvValueBindingParams;
        additionalHeadingBindingParams?: TAdvValueBindingParams;
        isOpenBindingParams?: TAdvValueBindingParams;

        // make the content of the groupbox act as a flex container
        contentAsFlexContainer?: boolean;

        ignoreDefaultStyle?: boolean;

        // a click on the group box results in this action
        onClickAction?: TAdvWebActionParams;

        minWidth?: string;
        maxWidth?: string;
        minHeight?: string;
        maxHeight?: string;

        shrinkBindingParams?: TAdvValueBindingParams;
        growBindingParams?: TAdvValueBindingParams;
        alignBindingParams?: TAdvValueBindingParams;
        basisBindingParams?: TAdvValueBindingParams;
        minWidthBindingParams?: TAdvValueBindingParams;
        maxWidthBindingParams?: TAdvValueBindingParams;
        minHeightBindingParams?: TAdvValueBindingParams;
        maxHeightBindingParams?: TAdvValueBindingParams;
    };
