import AdvText, { AdvHeadlineSmall } from "@components/data/text/text";
import AdvModal from "@components/layout/modal/modal";
import AdvStack from "@components/layout/stack/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import AdvIcon from "@components/other/icon/icon";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { CircleInfoIcon } from "@themes/icons";
import getConfig from "next/config";
import { useState } from "react";

import useAdvTheme from "@hooks/useAdvTheme";
import { advcatch, advlog } from "@utils/logging";
import HtmlToReact from "html-to-react";
import Image from "next/image";

export const AdvCredits = ({
    fullHeight,
    minimized = false,
    borderTop = false,
}: {
    fullHeight?: boolean;
    minimized?: boolean;
    borderTop?: boolean;
}) => {
    const { publicRuntimeConfig } = getConfig();

    const [isModalOpen, setIsModalOpenReal] = useState(false);
    const [htmlText, setHtmlText] = useState(<></>);
    const theme = useAdvTheme();

    const onText = useAdvCallback((textInp: string) => {
        let text = textInp.replaceAll(" <", " &lt;");
        text = text.replaceAll("> ", "&gt; ");
        const htmlToReactParser: any = new (HtmlToReact.Parser as ObjectConstructor)();
        const processNodeDefinitions: any =
            new (HtmlToReact.ProcessNodeDefinitions as ObjectConstructor)();
        const processingInstructions = [
            {
                shouldProcessNode: function (node: any) {
                    return ["html", "body"].includes(node.name);
                },
                processNode: function (node: any, children: any) {
                    if (node.name === "html") {
                        return <div key="licenseView">{children}</div>;
                    } else if (node.name === "body") {
                        return children;
                    }
                },
            },
            {
                // Anything else
                shouldProcessNode: function () {
                    return true;
                },
                processNode: processNodeDefinitions.processDefaultNode,
            },
        ];
        const reactComponent = htmlToReactParser.parseWithInstructions(
            text,
            () => true,
            processingInstructions,
        );
        setHtmlText(reactComponent as React.JSX.Element);
    }, []);

    const setIsModalOpen = useAdvCallback(
        (state: boolean) => {
            if (state) {
                fetch("dynamic/license.html")
                    .then((r) => {
                        if (r.ok) {
                            r.text().then(onText).catch(advcatch);
                        } else {
                            fetch("license.html")
                                .then((r) => r.text())
                                .then(onText)
                                .catch((r) => {
                                    // ignore
                                    advlog("no license.html found: ", r);
                                });
                        }
                    })
                    .catch(advcatch);
            }
            setIsModalOpenReal(state);
        },
        [onText],
    );

    return (
        <>
            <AdvStack
                horizontal
                verticalAlign="center"
                horizontalAlign="center"
                verticalFill
                styles={{
                    root: {
                        cursor: "pointer",
                        height: borderTop ? "100%" : undefined,
                        width: borderTop ? "100%" : undefined,
                        borderTop: borderTop
                            ? `1px solid ${theme.palette.neutralQuaternary}`
                            : undefined,
                    },
                }}
                onClick={() => setIsModalOpen(true)}
            >
                <AdvIcon iconName={CircleInfoIcon.iconName}></AdvIcon>
                {minimized !== true && (
                    <AdvStackItem>
                        <AdvText
                            ignoreTranslation
                            key="versionNumber"
                            id="versionNumber"
                            styles={{
                                root: {
                                    pointerEvents: "none",
                                    // color: theme.palette.accent,
                                    lineHeight: fullHeight === true ? "44px" : undefined,
                                    fontSize: borderTop ? "12px" : undefined,
                                },
                            }}
                            label={`Version ${String(publicRuntimeConfig?.version)}`}
                        />
                    </AdvStackItem>
                )}
            </AdvStack>
            <AdvModal
                isOpen={isModalOpen}
                headline={"Credits"}
                onDismiss={() => {
                    setIsModalOpen(false);
                }}
                styles={{ root: { main: { minWidth: "80%" } } }}
            >
                <AdvStack verticalFill>
                    <AdvStack styles={{ root: { width: "80%", margin: "auto" } }}>
                        <AdvStackItem>
                            <Image
                                src="/images/footer.png"
                                alt={"advantex logo"}
                                width={260}
                                height={52}
                            ></Image>
                        </AdvStackItem>
                        <AdvStackItem>
                            <AdvStack>
                                <AdvText>Copyright AdvanTex Software GmbH & Co. KG</AdvText>
                                <AdvText>48429 Rheine, Germany</AdvText>
                                <AdvText>E-Mail: info@advantex.de</AdvText>
                                <AdvText>Internet: www.advantex.de</AdvText>
                            </AdvStack>
                        </AdvStackItem>
                    </AdvStack>
                    <AdvStackItem styles={{ root: { width: "80%", margin: "auto" } }}>
                        <AdvHeadlineSmall>Third party software</AdvHeadlineSmall>
                    </AdvStackItem>
                    <AdvStackItem>{htmlText}</AdvStackItem>
                </AdvStack>
            </AdvModal>
        </>
    );
};
