import AdvText from "@components/data/text/text";
import { AdvButtonPure, TAdvButtonProps } from "@components/inputs/button/button-pure";
import { BaseButton } from "@fluentui/react";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffectOnce } from "@hooks/react-overload/useAdvEffect";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { OrderIcon } from "@themes/icons";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { advcatch } from "@utils/logging";
import React, { MouseEvent, useMemo } from "react";
import { useWebOrder, webOrderPanelOpenAtom } from "./web-order-panel";

export type TAdvWebOrderButtonProps = Omit<TAdvButtonProps, "options" | "label" | "value"> & {};
const AdvWebOrderButtonComp = ({ onClick, ...props }: TAdvWebOrderButtonProps) => {
    useAdvComponent(AdvWebOrderButtonComp, props);

    const mainFontSize = 28;
    const mainPadding = 8;

    const setPanelVisible = useAdvSetRecoilState(webOrderPanelOpenAtom);

    const { webOrderArr, reloadWebOrdersAsync } = useWebOrder();

    useAdvEffectOnce(() => {
        // Initial die Web-Aufträge laden
        reloadWebOrdersAsync().catch(advcatch);
    });

    const hasWebOrders = useMemo(
        () => webOrderArr !== undefined && webOrderArr.length > 0,
        [webOrderArr],
    );

    // Nur Child-WebAuftr (ParentID > 0) und WebAuftr ohne Child-WebAuftr zählen
    const anzWebOrders = useMemo(
        () =>
            webOrderArr === undefined || webOrderArr.length == 0
                ? 0
                : webOrderArr.filter(
                      (woA) =>
                          woA.ParentID > 0 || !webOrderArr.some((woB) => woB.ParentID == woA.ID),
                  ).length,
        [webOrderArr],
    );

    const handleButtonClicked = useAdvCallback(
        (
            event: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement,
                globalThis.MouseEvent
            >,
        ) => {
            setPanelVisible(true);
            if (onClick !== undefined) {
                onClick(event);
            }
        },
        [setPanelVisible, onClick],
    );

    const theme = useAdvTheme();
    return (
        <div style={{ position: "relative" }}>
            <AdvButtonPure
                iconName={OrderIcon.iconName}
                iconProps={{
                    color: hasWebOrders ? theme.palette.themePrimary : theme.palette.neutralPrimary,
                }}
                styles={{
                    icon: {
                        color: hasWebOrders
                            ? theme.palette.themePrimary
                            : theme.palette.neutralPrimary,
                        fontSize: mainFontSize,
                    },
                    root: {
                        borderWidth: 0,
                        paddingLeft: mainPadding,
                        paddingRight: mainPadding,
                        minWidth: 0,
                    },
                }}
                onClick={handleButtonClicked}
            />
            {hasWebOrders == false ? null : (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        transform: "translate(25%, -25%)",
                        pointerEvents: "none",
                        display: "grid",
                        justifyItems: "center",
                    }}
                >
                    <AdvText
                        label={String(anzWebOrders)}
                        variant="tiny"
                        styles={{
                            root: {
                                color: theme.palette.white,
                                background: `radial-gradient(circle, ${theme.palette.red} 70%, rgba(0, 0, 0, 0) 71%)`,
                                padding: "max(15%, 2px) max(20%, 5px)",
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

const AdvWebOrderButton = React.memo(AdvWebOrderButtonComp, deepCompareJSXProps);
export default AdvWebOrderButton;
