import { useMemo } from "react";
import useTranslationArray, { TAdvTranslation } from "./useTranslationArray";

export type TAdvTranslationText = {
    text: string;
    ignoreTranslation?: boolean; // TODO! not used everywhere
};

export const toAdvText = (
    text: { text: string } | string,
    ignoreTranslation?: boolean,
): TAdvTranslationText => {
    if (typeof text === "string") {
        return { text, ignoreTranslation };
    } else {
        return { text: text.text, ignoreTranslation: ignoreTranslation };
    }
};

export const useTranslation = (
    textToTranslate: string | TAdvTranslationText | undefined,
    dontTranslate: boolean = false,
): TAdvTranslation => {
    if (typeof textToTranslate === "object") {
        dontTranslate = textToTranslate.ignoreTranslation ?? false;
        textToTranslate = textToTranslate.text;
    }

    const textsToTranslate = useMemo(() => {
        if (textToTranslate === undefined) return [undefined];
        return dontTranslate
            ? [String(textToTranslate)]
            : String(textToTranslate).replaceAll("\n", " ").split("|");
    }, [textToTranslate, dontTranslate]);

    const translationItems = useTranslationArray(textsToTranslate, dontTranslate);

    const res = useMemo(() => {
        return (
            translationItems
                // MSC: Brauchen wir wahrscheinlich erstmal nicht
                // .map((item, index) => {
                //     if (item.t === undefined || item.hasErr) return item;

                //     if (
                //         textsToTranslate.length == 0 ||
                //         (textsToTranslate.length == 1 && textsToTranslate[0] === undefined)
                //     )
                //         return item;

                //     if (textsToTranslate[index] === undefined || textsToTranslate[index] == "")
                //         return item;

                //     // Trim vom urspr. String wieder hinzufügen
                //     item.t = str_apply_trim(textsToTranslate[index], item.t);
                //     item.text = item.t;

                //     return item;
                // })
                .reduce((a, b) => {
                    return {
                        t: (a.t ?? "") + " " + (b.t ?? ""),
                        text: (a.text ?? "") + " " + (b.text ?? ""),
                        hasErr: a.hasErr || b.hasErr,
                    };
                })
        );
    }, [translationItems]);

    return res;
};

export { useTranslation as useT };
