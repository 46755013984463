import AdvText, { AdvHeadlineSmall } from "@components/data/text/text";
import AdvWarteDialog from "@components/dialogs/warte/warte";
import { AdvButtonPure } from "@components/inputs/button/button-pure";
import AdvModal from "@components/layout/modal/modal";
import AdvIcon from "@components/other/icon";
import AdvLoading, { EAdvLoadingMode } from "@components/other/loading/loading";
import { LAN } from "@data/language/strings";
import {
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerHeaderTitle,
    Field,
    FluentProvider,
    OverlayDrawer,
    OverlayDrawerProps,
    ProgressBar,
    Tree,
    TreeItem,
    TreeItemLayout,
} from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import useAdvError from "@hooks/dialogs/useAdvError";
import { usePageFunctions } from "@hooks/page/usePageListener";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilState from "@hooks/recoil-overload/useAdvRecoilState";
import useAdvComponent from "@hooks/useAdvComponent";
import { TSuccessClass, useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import useAdvTheme from "@hooks/useAdvTheme";
import {
    CircleInfoIcon,
    CloseIcon,
    DeleteIcon,
    RefreshIcon,
    WarningIcon,
    WarningTextRoundIcon,
} from "@themes/icons";
import { UnixToDateTime } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import React, { useMemo, useState } from "react";
import { atom } from "recoil";
type TAdvOrder = {
    ID: number;
    Bez: string;
    Status: string;
    StatusBez: string;
    AuftragsNr: string;
    UnixZeitpunkt: number;
    KundenNr: string;
    KundenName: string;
};
type TWebOrder = {
    ID: number;
    ParentID: number;
    Bez: string;
    Typ: string;
    Status: string;
    StatusBez: string;
    FreigabeNoetig: boolean;
};
type TWebOrderResponse = {
    AdvOrder: TAdvOrder;
    WebOrderArr: TWebOrder[];
};
type TWebOrderMemoResponse = {
    Value: string;
};

// const WEBAUFTR_ERFASST = "A";
const WEBAUFTR_FEHLER = "D";
const WEBAUFTR_ABGEBROCHEN = "G";
// const WEBAUFTR_ERLEDIGT = "W";

// const AUFTRAG_ERFASST = "A";
// const AUFTRAG_FREIGABE_NOETIG = "K";
// const AUFTRAG_ERLEDIGT = "W";
// const AUFTRAG_UNGUELTIG = "Y";

const gAdvOrder = atom<TAdvOrder | undefined>({
    key: "gAdvOrder",
    default: undefined,
});

const gWebOrderArr = atom<TWebOrder[] | undefined>({
    key: "gWebOrderArr",
    default: undefined,
});

export const webOrderPanelOpenAtom = atom<boolean>({
    key: "webOrderPanelOpen",
    default: false,
});

export const useWebOrder = () => {
    const [advOrder, setAdvOrder] = useAdvRecoilState(gAdvOrder); //why atom
    const [webOrderArr, setWebOrderArr] = useAdvRecoilState(gWebOrderArr);

    const { sendCallbackRequest } = useAdvSocketCallback();
    const { showError } = useAdvError();
    const { reloadPageData } = usePageFunctions();

    const reloadWebOrdersAsync = async () => {
        const res: TWebOrderResponse = await sendCallbackRequest("session", "get_weborders", {});
        setAdvOrder(res.AdvOrder);
        setWebOrderArr(res.WebOrderArr.filter((wo) => wo.Status < WEBAUFTR_ABGEBROCHEN));
    };
    const cancelWebOrderAsync = async (...items: TWebOrder[]) => {
        const idsToRemove = items.map((wo) => wo.ID);
        const res: TSuccessClass = await sendCallbackRequest("session", "cancel_weborders", {
            IDs: idsToRemove,
        });
        if (res.Success == false) {
            showError(LAN.WARENKORB_ERROR.text);
        }
        await reloadWebOrdersAsync();
    };
    const approveOrderAsync = async () => {
        const res: TSuccessClass = await sendCallbackRequest("session", "approve_weborders", {});
        if (res.Success == false) {
            showError(LAN.WARENKORB_ERROR.text);
        } else {
            reloadPageData();
        }

        await reloadWebOrdersAsync();
    };
    const fetchWebOrderMemoAsync = async (item: TWebOrder) => {
        const res: TWebOrderMemoResponse = await sendCallbackRequest(
            "session",
            "get_weborder_memo",
            { ID: item.ID },
        );
        return res.Value;
    };

    return {
        advOrder,
        webOrderArr,
        reloadWebOrdersAsync,
        cancelWebOrderAsync,
        approveOrderAsync,
        fetchWebOrderMemoAsync,
    };
};

function getItemName(webOrder: TGroupedWebOrder) {
    return `tree-item-${webOrder.Parent.ID}`;
}

type TGroupedWebOrder = { Parent: TWebOrder; Children: TGroupedWebOrder[] };
export type TAdvWebOrderPanelProps = OverlayDrawerProps & {
    close: () => void;
};

const AdvWebOrderPanelComp = ({ close, ...props }: TAdvWebOrderPanelProps) => {
    useAdvComponent(AdvWebOrderPanelComp, props);

    const {
        advOrder,
        webOrderArr,
        reloadWebOrdersAsync,
        cancelWebOrderAsync,
        approveOrderAsync,
        fetchWebOrderMemoAsync,
    } = useWebOrder();

    const theme = useAdvTheme();
    const v9Theme = useMemo(() => {
        return createV9Theme(theme);
    }, [theme]);

    const [isApproveWeiterVisible, setIsApproveWeiterVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isMemoVisible, setMemoVisible] = useState<boolean>(false);
    const [currentMemo, setCurrentMemo] = useState<string | undefined>(undefined);

    const cancelWebOrder = useAdvCallback(
        (...items: TWebOrder[]) => {
            if (isLoading) return;
            setIsLoading(true);
            cancelWebOrderAsync(...items)
                .then(() => {
                    setIsLoading(false);
                })
                .catch(advcatch);
        },
        [cancelWebOrderAsync, isLoading],
    );

    const approveOrder = useAdvCallback(() => {
        if (isLoading) return;
        setIsLoading(true);
        // Hinweis wenn Freigabe nötig ist, dass der Auftrag noch freigegeben werden muss
        if (webOrderArr?.find((wo) => wo.FreigabeNoetig) !== undefined)
            setIsApproveWeiterVisible(true);
        approveOrderAsync()
            .then(() => {
                setIsLoading(false);
            })
            .catch(advcatch);
    }, [approveOrderAsync, isLoading, webOrderArr]);

    const reloadWebOrders = useAdvCallback(() => {
        if (isLoading) return;
        setIsLoading(true);
        reloadWebOrdersAsync()
            .then(() => {
                setIsLoading(false);
            })
            .catch(advcatch);
    }, [reloadWebOrdersAsync, isLoading]);

    const handleShowMemo = useAdvCallback(
        (item: TWebOrder) => {
            if (isLoading) return;
            setIsLoading(true);
            fetchWebOrderMemoAsync(item)
                .then((value) => {
                    setCurrentMemo(value);
                    setMemoVisible(true);
                    setIsLoading(false);
                })
                .catch(advcatch);
        },
        [fetchWebOrderMemoAsync, isLoading],
    );

    const groupedwebOrderArr = useMemo<TGroupedWebOrder[]>(() => {
        const res: TGroupedWebOrder[] = [];
        if (webOrderArr === undefined || webOrderArr.length == 0) return res;

        const temp = deepCopy(webOrderArr);
        // Rekursiv Parent -> Children
        const fillArray = (parentID: number, sortedArr: TGroupedWebOrder[]) => {
            const parents = temp
                .filter((wo) => wo.ParentID == parentID)
                .sort((a, b) => a.ID - b.ID);

            parents.forEach((wo) => {
                const children: TGroupedWebOrder[] = [];
                fillArray(wo.ID, children);

                const item: TGroupedWebOrder = { Parent: wo, Children: children };
                sortedArr.push(item);
            });
        };

        fillArray(-1, res); // -1 = Parents

        return res;
    }, [webOrderArr]);

    const cancelOrder = useAdvCallback(() => {
        if (webOrderArr === undefined) return;
        if (confirm(LAN.WARENKORB_CONFIRM_CANCEL_ALL.text)) {
            cancelWebOrder(...webOrderArr.filter((wo) => wo.Status < WEBAUFTR_ABGEBROCHEN));
        }
    }, [cancelWebOrder, webOrderArr]);

    return (
        <FluentProvider theme={v9Theme} style={{ flexGrow: 1 }}>
            <AdvWarteDialog
                hidden={!isMemoVisible}
                onClosed={() => setMemoVisible(false)}
                text={currentMemo ?? ""}
                title={LAN.WARENKORB_AUFTR_DETAILS.text}
                ignoreTranslation
                maxWidth={"50%"}
            />
            <AdvWarteDialog
                hidden={!isApproveWeiterVisible}
                onClosed={() => setIsApproveWeiterVisible(false)}
                text={LAN.WARENKORB_APPROVAL_INFO.text}
                title={`${LAN.WARENKORB_APPROVE.text} - ${LAN.WARENKORB_REQUIRE_APPROVAL.text}`}
            />
            <AdvModal
                isOpen={isLoading}
                headline={LAN.WARENKORB.text}
                onDismiss={() => {}}
                hideCloseButton
            >
                <Field validationMessage="Verarbeite..." validationState="none">
                    <ProgressBar hidden={false} />
                </Field>
            </AdvModal>
            <OverlayDrawer {...props} position="end" size="medium">
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <>
                                <AdvButtonPure
                                    iconName={RefreshIcon.iconName}
                                    onClick={reloadWebOrders}
                                    styles={{
                                        root: {
                                            borderWidth: 0,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            minWidth: 0,
                                        },
                                    }}
                                />
                                <AdvButtonPure
                                    iconName={CloseIcon.iconName}
                                    onClick={close}
                                    styles={{
                                        root: {
                                            borderWidth: 0,
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                            minWidth: 0,
                                        },
                                    }}
                                />
                            </>
                        }
                    >
                        <AdvHeadlineSmall
                            label={LAN.WARENKORB_HEADLINE.text}
                            styles={{ root: { display: "block", lineHeight: "normal" } }}
                        />
                        {advOrder === undefined || advOrder.ID == -1 ? (
                            <AdvText label={LAN.WARENKORB_EMPTY.text} />
                        ) : (
                            <>
                                <AdvText
                                    label={`${LAN.WARENKORB_NR.text}: ${advOrder!.AuftragsNr}`}
                                    title={`${LAN.WARENKORB_STARTED.text}: ${UnixToDateTime(
                                        advOrder!.UnixZeitpunkt,
                                    ).toLocaleString()}`}
                                    styles={{ root: { display: "block", lineHeight: "normal" } }}
                                />
                                <AdvText
                                    label={`${LAN.CUSTOMER.text}: ${advOrder!.KundenNr} ${
                                        advOrder!.KundenName
                                    }`}
                                    styles={{ root: { display: "block", lineHeight: "normal" } }}
                                />
                            </>
                        )}
                    </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody style={{ paddingRight: "14px" }}>
                    <AdvLoading isLoading={isLoading} mode={EAdvLoadingMode.ShowLoadingAsOverlay}>
                        {groupedwebOrderArr.length <= 0 ? null : (
                            <Tree
                                defaultOpenItems={groupedwebOrderArr.map(getItemName)}
                                style={{
                                    boxShadow: theme.effects.elevation16,
                                    background: theme.palette.themeLight,
                                    margin: "8px 0 16px 0",
                                }}
                            >
                                {groupedwebOrderArr.map((children) => (
                                    <AdvWebOrderPanelItem
                                        key={`WebOrderitem_${children.Parent.ID}`}
                                        groupedWebOrder={children}
                                        cancelWebOrder={cancelWebOrder}
                                        showMemo={handleShowMemo}
                                        disabled={isLoading}
                                    />
                                ))}
                            </Tree>
                        )}
                        {groupedwebOrderArr.length == 0 &&
                        advOrder !== undefined &&
                        advOrder.ID > -1 ? (
                            <AdvHeadlineSmall>Keine Aufträge</AdvHeadlineSmall>
                        ) : null}
                    </AdvLoading>
                </DrawerBody>

                <DrawerFooter>
                    <AdvButtonPure
                        disabled={advOrder === undefined || advOrder.ID == -1 || isLoading}
                        text={LAN.WARENKORB_APPROVE.text}
                        styles={{
                            root: {
                                fontSize: 20,
                                height: 42,
                                flexGrow: 1,
                                background:
                                    advOrder === undefined || advOrder.ID == -1
                                        ? undefined
                                        : theme.palette.green,
                                color: "white",
                                "svg.adv-icon": { color: "white" },
                            },
                            icon: { fontSize: 24, width: 24, height: 24 },
                        }}
                        onClick={approveOrder}
                    />
                    <AdvButtonPure
                        iconName={DeleteIcon.iconName}
                        title={LAN.WARENKORB_CANCEL_ALL.text}
                        disabled={advOrder === undefined || advOrder.ID == -1 || isLoading}
                        styles={{
                            root: {
                                fontSize: 26,
                                width: "auto",
                                height: 42,
                                background:
                                    advOrder === undefined || advOrder.ID == -1
                                        ? undefined
                                        : theme.palette.redDark,
                                color: "white",
                                "svg.adv-icon": { color: "white" },
                            },
                            icon: { fontSize: 24, width: 24, height: 24 },
                        }}
                        onClick={cancelOrder}
                    />
                </DrawerFooter>
            </OverlayDrawer>
        </FluentProvider>
    );
};

// Die ParentWebAuftr enthalten idR. kein Memo, deshalb auch nicht aufrufbar machen
const ExcludeInfoAuftrTypes = ["RESIDENT", "WEARER", "ORDER", "ANPROBE"];

export type TAdvWebOrderPanelItemProps = {
    groupedWebOrder: TGroupedWebOrder;
    cancelWebOrder: (items: TWebOrder) => void;
    showMemo: (item: TWebOrder) => void;
    disabled?: boolean;
};
const AdvWebOrderPanelItem = ({
    groupedWebOrder,
    cancelWebOrder,
    showMemo,
    disabled = false,
}: TAdvWebOrderPanelItemProps) => {
    const { Children, Parent: currentItem } = groupedWebOrder;

    const [isOpen, { toggle: toggleIsOpen }] = useBoolean(true);
    const theme = useAdvTheme();

    return (
        <TreeItem
            itemType={Children.length > 0 ? "branch" : "leaf"}
            value={getItemName(groupedWebOrder)}
            open={isOpen}
        >
            <TreeItemLayout
                iconBefore={
                    currentItem.Status == WEBAUFTR_FEHLER ? (
                        <AdvIcon
                            iconName={WarningIcon.iconName}
                            title={LAN.WARENKORB_POS_ERROR.text}
                        />
                    ) : currentItem.FreigabeNoetig == false ? undefined : (
                        <AdvIcon
                            iconName={WarningTextRoundIcon.iconName}
                            title={LAN.WARENKORB_POS_REQUIRE_APPROVAL.text}
                        />
                    )
                }
                actions={
                    <div style={{ display: "flex" }}>
                        {ExcludeInfoAuftrTypes.indexOf(currentItem.Typ) >= 0 ? undefined : (
                            <AdvButtonPure
                                iconName={CircleInfoIcon.iconName}
                                disabled={disabled}
                                styles={{
                                    root: {
                                        borderWidth: 0,
                                        minWidth: 0,
                                        background: "transparent",
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        "svg.adv-icon": { color: theme.palette.blue },
                                        opacity: 1,
                                    },
                                }}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    showMemo(currentItem);
                                }}
                            />
                        )}
                        <AdvButtonPure
                            iconName={DeleteIcon.iconName}
                            disabled={disabled}
                            styles={{
                                root: {
                                    borderWidth: 0,
                                    minWidth: 0,
                                    background: "transparent",
                                    paddingLeft: 8,
                                    paddingRight: 4,
                                    "svg.adv-icon": { color: theme.palette.redDark },
                                    opacity: 1,
                                },
                            }}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                cancelWebOrder(currentItem);
                            }}
                        />
                    </div>
                }
                aside={
                    <div style={{ display: "flex" }}>
                        {ExcludeInfoAuftrTypes.indexOf(currentItem.Typ) >= 0 ? undefined : (
                            <AdvButtonPure
                                title={LAN.WARENKORB_POS_DETAILS.text}
                                iconName={CircleInfoIcon.iconName}
                                disabled={disabled}
                                styles={{
                                    root: {
                                        borderWidth: 0,
                                        minWidth: 0,
                                        background: "transparent",
                                        paddingLeft: 4,
                                        paddingRight: 0,
                                        "svg.adv-icon": { color: theme.palette.blue },
                                        opacity: 0.75,
                                    },
                                }}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    showMemo(currentItem);
                                }}
                            />
                        )}
                        <AdvButtonPure
                            title={LAN.WARENKORB_POS_CANCEL.text}
                            iconName={DeleteIcon.iconName}
                            disabled={disabled}
                            styles={{
                                root: {
                                    borderWidth: 0,
                                    minWidth: 0,
                                    background: "transparent",
                                    paddingLeft: 12,
                                    paddingRight: 0,
                                    "svg.adv-icon": { color: theme.palette.redDark },
                                    opacity: 0.6,
                                },
                            }}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                cancelWebOrder(currentItem);
                            }}
                        />
                    </div>
                }
                onClick={(ev) => {
                    if (
                        ExcludeInfoAuftrTypes.indexOf(currentItem.Typ) >= 0 ||
                        Children.length > 0
                    ) {
                        toggleIsOpen();
                        return;
                    }
                    showMemo(currentItem);
                    ev.stopPropagation();
                }}
            >
                <AdvText
                    label={`${currentItem.Bez}`}
                    nowrap={false}
                    styles={{ root: { whiteSpace: "normal" } }}
                />
            </TreeItemLayout>
            <Tree>
                {Children.map((child) => (
                    <AdvWebOrderPanelItem
                        key={`WebOrderitem_${child.Parent.ID}`}
                        groupedWebOrder={child}
                        cancelWebOrder={cancelWebOrder}
                        showMemo={showMemo}
                        disabled={disabled}
                    />
                ))}
            </Tree>
        </TreeItem>
    );
};

const AdvWebOrderPanel = React.memo(AdvWebOrderPanelComp, deepCompareJSXProps);
export default AdvWebOrderPanel;
