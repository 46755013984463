import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { sessionAddInfosAtom } from "@data/session";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeInput } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { BaseButton } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { IsWebActionTrivial, useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { ButtonIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { MouseEvent, MouseEventHandler, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { AdvButtonContextMenu } from "./button-contextmenu";
import { AdvButtonPureNew, TAdvButtonProps } from "./button-pure";

const AdvButtonSimpleComp = ({ onClick, designerProps, ...props }: TAdvButtonProps) => {
    const handleButtonClick = useAdvCallback<
        MouseEventHandler<
            HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement
        >
    >(
        (
            event: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement,
                globalThis.MouseEvent
            >,
        ) => {
            if (onClick !== undefined) onClick(event as any);
            if (designerProps != undefined && designerProps.onClick !== undefined)
                designerProps.onClick(event);
        },
        [designerProps, onClick],
    );

    return (
        <AdvButtonPureNew
            {...props}
            onClick={handleButtonClick}
            designerProps={designerProps}
        ></AdvButtonPureNew>
    );
};
const AdvButtonSimple = React.memo(AdvButtonSimpleComp, deepCompareJSXProps);

const AdvButtonComplexAction = ({
    primaryBindingParams,
    webActionParams,
    keyRef,
    dataArrayIndex = 0,
    forceOwnText,
    forceAllowOnClick,
    iconName,
    hotkey,
    onClick,
    designerProps,
    disabled,
    children,
    ...props
}: TAdvButtonProps) => {
    const [actionName, actionIcon, actionFunc, shouldUseBoundValue, hasPermission, isDisabled] =
        useAdvWebAction(keyRef ?? "", dataArrayIndex, webActionParams);

    const handleButtonClick = useAdvCallback<
        MouseEventHandler<
            HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement
        >
    >(
        (
            event: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement,
                globalThis.MouseEvent
            >,
        ) => {
            if (!shouldUseBoundValue || designerProps != undefined || forceAllowOnClick === true) {
                if (onClick !== undefined) onClick(event as any);
                if (designerProps && designerProps.onClick !== undefined)
                    designerProps.onClick(event);
            } else if (shouldUseBoundValue) (actionFunc as Function)();
        },
        [actionFunc, designerProps, forceAllowOnClick, onClick, shouldUseBoundValue],
    );
    const shouldDisable = ((disabled ?? false) || isDisabled) && designerProps === undefined;

    useHotkeys(
        hotkey ?? "",
        (ev: KeyboardEvent) => {
            if (shouldUseBoundValue) (actionFunc as Function)();
            ev.stopPropagation();
            ev.preventDefault();
        },
        {
            enabled:
                hotkey !== undefined &&
                hotkey !== "" &&
                designerProps == undefined &&
                !shouldDisable,
        },
    );

    const session = useAdvRecoilValue(sessionAddInfosAtom);

    const buttonRef = useRef<any>(null);
    const [isContextMenuHidden, setIsContextMenuHidden] = useState(true);
    const handleContextMenuClick = useAdvCallback(
        (
            e: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement
            >,
        ) => {
            buttonRef.current = e.target;
            setIsContextMenuHidden(false);
            e.preventDefault();
        },
        [],
    );

    return designerProps != undefined || hasPermission ? (
        <>
            <AdvButtonPureNew
                {...props}
                primaryBindingParams={primaryBindingParams}
                webActionParams={webActionParams}
                keyRef={keyRef}
                designerProps={designerProps}
                forceOwnText={forceOwnText}
                onClick={handleButtonClick}
                onContextMenu={
                    designerProps == undefined && session.CanEditPermissions === true
                        ? handleContextMenuClick
                        : undefined
                }
                hotkey={hotkey}
                iconName={actionIcon ?? iconName}
                disabled={shouldDisable}
            >
                {shouldUseBoundValue && forceOwnText !== true ? (actionName as string) : children}
            </AdvButtonPureNew>
            <AdvButtonContextMenu
                buttonRef={buttonRef}
                isContextMenuHidden={isContextMenuHidden}
                setIsContextMenuHidden={setIsContextMenuHidden}
                webActionName={webActionParams?.actionName ?? ""}
            ></AdvButtonContextMenu>
        </>
    ) : (
        <></>
    );
};

const AdvButtonComplexDisabledAndHide = ({
    disabledBindingParams,
    disabled,
    advhide,
    advhideBindingParams,
    webActionParams,
    dataArrayIndex = 0,
    ...props
}: TAdvButtonProps) => {
    const [shouldDisabled] = useAdvValueBinderNoDataType(
        disabledBindingParams,
        disabled != undefined && disabled ? true : false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    if (!IsWebActionTrivial(webActionParams))
        return (
            <AdvButtonComplexAction
                {...props}
                disabled={shouldDisabled}
                disabledBindingParams={disabledBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonComplexAction>
        );
    else
        return (
            <AdvButtonPureNew
                {...props}
                disabled={shouldDisabled}
                disabledBindingParams={disabledBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonPureNew>
        );
};

const AdvButtonComplexFlexGrow = ({
    flexGrowBindingParams,
    flexGrow,
    disabledBindingParams,
    advhideBindingParams,
    webActionParams,
    dataArrayIndex = 0,
    ...props
}: TAdvButtonProps) => {
    const [shouldGrowInFlex] = useAdvValueBinderNoDataType(
        flexGrowBindingParams,
        flexGrow != undefined && flexGrow ? true : false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    if (
        !IsValueBindingTrivial(disabledBindingParams) ||
        !IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvButtonComplexDisabledAndHide
                {...props}
                flexGrow={shouldGrowInFlex}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonComplexDisabledAndHide>
        );
    else if (!IsWebActionTrivial(webActionParams))
        return (
            <AdvButtonComplexAction
                {...props}
                flexGrow={shouldGrowInFlex}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonComplexAction>
        );
    else
        return (
            <AdvButtonPureNew
                {...props}
                flexGrow={shouldGrowInFlex}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonPureNew>
        );
};

const AdvButtonComplexPrimaryOrSimplified = ({
    primaryBindingParams,
    primary,
    simplifiedBindingParams,
    simplified,
    flexGrowBindingParams,
    disabledBindingParams,
    advhideBindingParams,
    webActionParams,
    dataArrayIndex = 0,
    ...props
}: TAdvButtonProps) => {
    const [isPrimary] = useAdvValueBinderNoDataType(
        primaryBindingParams,
        primary != undefined && primary ? true : false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isSimplified] = useAdvValueBinderNoDataType(
        simplifiedBindingParams,
        simplified != undefined && simplified ? true : false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    if (!IsValueBindingTrivial(flexGrowBindingParams))
        return (
            <AdvButtonComplexFlexGrow
                {...props}
                primary={isPrimary}
                primaryBindingParams={primaryBindingParams}
                simplified={isSimplified}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonComplexFlexGrow>
        );
    else if (
        !IsValueBindingTrivial(disabledBindingParams) ||
        !IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvButtonComplexDisabledAndHide
                {...props}
                primary={isPrimary}
                primaryBindingParams={primaryBindingParams}
                simplified={isSimplified}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonComplexDisabledAndHide>
        );
    else if (!IsWebActionTrivial(webActionParams))
        return (
            <AdvButtonComplexAction
                {...props}
                primary={isPrimary}
                primaryBindingParams={primaryBindingParams}
                simplified={isSimplified}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonComplexAction>
        );
    else
        return (
            <AdvButtonPureNew
                {...props}
                primary={isPrimary}
                primaryBindingParams={primaryBindingParams}
                simplified={isSimplified}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
                dataArrayIndex={dataArrayIndex}
            ></AdvButtonPureNew>
        );
};

const AdvButtonComplex = ({
    primaryBindingParams,
    simplifiedBindingParams,
    flexGrowBindingParams,
    disabledBindingParams,
    advhideBindingParams,
    ...props
}: TAdvButtonProps) => {
    if (
        !IsValueBindingTrivial(primaryBindingParams) ||
        !IsValueBindingTrivial(simplifiedBindingParams)
    )
        return (
            <AdvButtonComplexPrimaryOrSimplified
                {...props}
                primaryBindingParams={primaryBindingParams}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvButtonComplexPrimaryOrSimplified>
        );
    else if (!IsValueBindingTrivial(flexGrowBindingParams))
        return (
            <AdvButtonComplexFlexGrow
                {...props}
                primaryBindingParams={primaryBindingParams}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvButtonComplexFlexGrow>
        );
    else if (
        !IsValueBindingTrivial(disabledBindingParams) ||
        !IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvButtonComplexDisabledAndHide
                {...props}
                primaryBindingParams={primaryBindingParams}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvButtonComplexDisabledAndHide>
        );
    else
        return (
            <AdvButtonComplexAction
                {...props}
                primaryBindingParams={primaryBindingParams}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvButtonComplexAction>
        );
};

/**
 * @summary Wrapper für ``DefaultButton`` / ``PrimaryButton``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/button
 */
const AdvButtonComp = ({
    primaryBindingParams,
    simplifiedBindingParams,
    flexGrowBindingParams,
    disabledBindingParams,
    advhideBindingParams,
    webActionParams,
    ...props
}: TAdvButtonProps) => {
    if (
        IsValueBindingTrivial(primaryBindingParams) &&
        IsValueBindingTrivial(simplifiedBindingParams) &&
        IsValueBindingTrivial(flexGrowBindingParams) &&
        IsValueBindingTrivial(disabledBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsWebActionTrivial(webActionParams)
    )
        return (
            <AdvButtonSimple
                {...props}
                primaryBindingParams={primaryBindingParams}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
            ></AdvButtonSimple>
        );
    else
        return (
            <AdvButtonComplex
                {...props}
                primaryBindingParams={primaryBindingParams}
                simplifiedBindingParams={simplifiedBindingParams}
                flexGrowBindingParams={flexGrowBindingParams}
                disabledBindingParams={disabledBindingParams}
                advhideBindingParams={advhideBindingParams}
                webActionParams={webActionParams}
            ></AdvButtonComplex>
        );
};

const AdvButton = React.memo(AdvButtonComp, deepCompareJSXProps);
export default AdvButton;

registerDesignableComponent({
    staticData: {
        name: LAN.BUTTON.text,
        type: EComponentTypeInput.Button,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: ButtonIcon,
    },
    properties: [
        AdvProperty.Boolean.create(
            toAdvText(LAN.PRIMARY),
            "primary",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.BUTTON_PRIMARY_DESCR),
            false,
        ),
        AdvProperty.Action.create(
            toAdvText(LAN.WEBACTION),
            "webActionParams",
            toAdvText(LAN.WEBACTION),
            toAdvText(LAN.BUTTON_WEB_ACTION_PARAMS_DESCR),
        ),
        AdvProperty.Text.createHotkey(
            toAdvText(LAN.HOTKEY),
            "hotkey",
            toAdvText(LAN.CONTROLS),
            toAdvText(LAN.BUTTON_HOTKEY_DESCR),
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.AUTO_GROW),
            "flexGrow",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.BUTTON_GROW_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DISABLED),
            "disabled",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.BUTTON_DISABLED_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.SIMPLIFIED_DESIGN),
            "simplified",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.BUTTON_SIMPLIFIED_DESCR),
            false,
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
