import "react-toastify/dist/ReactToastify.min.css";
import "../styles/globals.css";
import "../styles/react-grid-layout.css";
import "../styles/react-resize.css";

import AdvStack from "@components/layout/stack";
import PageLayouts from "@components/layouts";
import AdvErrorBoundary from "@components/other/error-boundary";
import DebugObserver from "@components/other/recoil-observer";
import AdvSessionReconnector from "@components/other/SessionReconnector";
import { EPageComponentSizeType, TPageComponentProps } from "@components/page-component";
import { initializeIcons, ThemeProvider } from "@fluentui/react";
import { useSsrCompletedState } from "@hooks/misc/useSsrCompletedState";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useStorageListener } from "@hooks/storage/useStorageListener";
import { useAdvEvent } from "@hooks/useAdvEvent";
import { useAdvSocket, useAdvSocketServer } from "@hooks/useAdvSocket";
import { useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import { ReactHooksWrapper, setHook } from "@utils/react-hooks-outside";
import { useMemo, useState } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import { RecoilRoot, selector } from "recoil";

/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { themeSelectorImpl } from "@data/theme/atoms";
import { AdvErrorDialog } from "@hooks/dialogs/useAdvError";
import { AdvInfoDialog } from "@hooks/dialogs/useAdvInfo";
import { usePageListener } from "@hooks/page/usePageListener";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useNativeErrorHandler } from "@hooks/useNativeErrorHandler";
import type { AppProps } from "next/app";
import { useResizeDetector } from "react-resize-detector";

initializeIcons("/fonts/", { disableWarnings: true });

export const themeSelector = selector({
    key: "app_themeMainSelector",
    get: ({ get }) => {
        return get(themeSelectorImpl({ themeName: "main" })).theme;
    },
});

/**
 * Einfach einkommentieren. Dann stehen in der Konsole Performance-Daten, z.B. wie lange eine Seite zum rendern brauchte.
 * Measuring performance: https://nextjs.org/docs/advanced-features/measuring-performance
 */
// export function reportWebVitals(metric: NextWebVitalsMetric)
// {
//     console.debug(`[${metric.label}] ${metric.name} start: ${metric.startTime.toFixed(1)} value: ${metric.value.toFixed(1)}`)
// }

function MyRecoilAppLogic() {
    useStorageListener();
    usePageListener();
    return <></>;
}

function MyRecoilAppWebsocketSession({ children }: { children: React.JSX.Element }) {
    const { readyState } = useAdvSocketServer();
    return <AdvSessionReconnector readyState={readyState}>{children}</AdvSessionReconnector>;
}

const sizeSwitches = [
    400, // small mobile
    600, // huge mobile
    800, // tablet
    1200, // desktop
    2000, // desktop wide
];

/** */
function MyRecoilApp({ Component, pageProps }: AppProps) {
    const theme = useAdvRecoilValue(themeSelector); // <- Kann nur innerhalb von <RecoilRoot> genutzt werden, deshalb MyRecoilApp

    const setSsrCompleted = useSsrCompletedState();
    useAdvEffect(setSsrCompleted, [setSsrCompleted]);

    const pageCompStyle = useMemo(() => {
        return {
            root: {
                padding: 4,
            },
        };
    }, []);

    const stackTokens = useMemo(() => {
        return { childrenGap: 0 };
    }, []);

    useNativeErrorHandler();

    const { ref: refPage, width: pageWidth } = useResizeDetector<HTMLDivElement>({
        handleWidth: true,
    });
    const {
        ref: refInner,
        height: innerHeight,
        width: innerWidth,
    } = useResizeDetector<HTMLDivElement>({
        handleHeight: true,
        handleWidth: true,
    });

    const pageSizeType: EPageComponentSizeType = useMemo(() => {
        let pageSizeType: EPageComponentSizeType = EPageComponentSizeType.SmallMobile;
        sizeSwitches.forEach((val, index) => {
            if ((pageWidth ?? 1200) > val) pageSizeType = index as EPageComponentSizeType;
        });
        return pageSizeType;
    }, [pageWidth]);

    const pageCompProps: TPageComponentProps = useMemo(() => {
        //put this into recoil?
        return {
            size: {
                width: innerWidth ?? 1200,
                height: innerHeight ?? 800,
            },
            sizeType: pageSizeType,
        };
    }, [innerHeight, innerWidth, pageSizeType]);

    const [page, setPage] = useState(<></>);
    useAdvEffect(() => {
        setPage(
            <PageLayouts.WithNav pageLayout={pageSizeType}>
                {/* Mit diesem ErrorBoundary können Laufzeit-Fehler nicht aus dem Content "ausbrechen" (aka die ganze Seite lahmlegen). */}
                <AdvErrorBoundary>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                        }}
                        ref={refInner}
                    >
                        <AdvStack verticalFill grow tokens={stackTokens}>
                            <AdvStack verticalFill styles={pageCompStyle} grow tokens={stackTokens}>
                                <Component {...pageProps} {...pageCompProps} />
                            </AdvStack>
                        </AdvStack>
                    </div>
                </AdvErrorBoundary>
            </PageLayouts.WithNav>,
        );
    }, [Component, pageCompProps, pageCompStyle, pageProps, pageSizeType, refInner, stackTokens]);

    return (
        <ThemeProvider theme={theme} applyTo="body">
            <MyRecoilAppWebsocketSession>
                <MyRecoilAppLogic></MyRecoilAppLogic>
            </MyRecoilAppWebsocketSession>
            <AdvErrorDialog />
            <AdvInfoDialog />
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
                ref={refPage}
            >
                {page}
            </div>
        </ThemeProvider>
    );
}

/**
 * This one is used to define the logic that should spread throughout the app.
 * Anything that should be present on every single view of the app belongs here.
 * Use it for <Provider>s, global definitions, application settings, and so on.
 */
function MyApp(props: AppProps) {
    setHook("useAdvEvent", useAdvEvent);
    setHook("useAdvSocket", useAdvSocket);
    setHook("useAdvSocketCallback", useAdvSocketCallback);

    return (
        <RecoilRoot>
            <DebugObserver />
            <MyRecoilApp {...props} />
            <ReactHooksWrapper />
            <ToastContainer
                position="bottom-right"
                theme="colored"
                transition={Zoom}
                autoClose={4000}
                pauseOnFocusLoss={false}
                pauseOnHover={true}
                enableMultiContainer
            />
        </RecoilRoot>
    );
}

export default MyApp;
