import AdvGrid from "@components/layout/grid";
import AdvGridItem from "@components/layout/grid/grid-item";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { EPageComponentSizeType } from "@components/page-component";
import AdvWebOrderPanel, { webOrderPanelOpenAtom } from "@feature/web-order/web-order-panel";
import { IconButton, Theme } from "@fluentui/react";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilState from "@hooks/recoil-overload/useAdvRecoilState";
import useAdvTheme from "@hooks/useAdvTheme";
import { BarsIcon } from "@themes/icons";
import React, { useMemo } from "react";
import { navIconButtonStyles } from "./styles";

export const NavElTopBar = ({
    parentTheme,
    setMenuMode,
    titleText,
    navHistory,
    persona,
    customerDropdown,
    webAuftragButton,
    mailButton,
    pageLayout = EPageComponentSizeType.DesktopWide,
}: {
    parentTheme: Theme;
    setMenuMode: React.Dispatch<React.SetStateAction<"iconnized" | "open" | "hidden">>;
    titleText: false | React.JSX.Element;
    navHistory: false | React.JSX.Element;
    persona: false | React.JSX.Element;
    customerDropdown: false | React.JSX.Element;
    webAuftragButton: false | React.JSX.Element;
    mailButton: false | React.JSX.Element;
    pageLayout?: EPageComponentSizeType;
}) => {
    const [isPanelVisible, setPanelVisible] = useAdvRecoilState(webOrderPanelOpenAtom);
    const theme = useAdvTheme();

    const navToggleIcon = useMemo(() => {
        return { iconName: BarsIcon.iconName };
    }, []);
    const onToggleNav = useAdvCallback(
        () =>
            setMenuMode((old) => {
                if (old == "hidden" || old == "iconnized") return "open";
                return (pageLayout ?? EPageComponentSizeType.DesktopWide) <=
                    EPageComponentSizeType.Mobile
                    ? "hidden"
                    : "iconnized";
            }),
        [pageLayout, setMenuMode],
    );

    const navGridEl = useMemo(() => {
        return {
            root: {
                overflowX: "hidden",
                padding: 7,
                boxSizing: "border-box",
                borderBottom: "1px solid " + parentTheme.palette.neutralLight,
            },
        };
    }, [parentTheme.palette.neutralLight]);

    const columnSizes = useMemo(() => {
        const cols = [
            "minmax(230px, auto)",
            "1fr",
            "20vw",
            "minmax(50px, auto)",
            "auto",
            "minmax(150px, auto)",
        ];
        if (pageLayout <= EPageComponentSizeType.Mobile) {
            cols[0] = "minmax(50px, auto)";
            cols[2] = "auto";
            cols[3] = "auto";
            cols[4] = "auto";
            cols[5] = "minmax(50px, auto)";
        }
        return cols.join(" ");
    }, [pageLayout]);

    return (
        <>
            <AdvStack grow styles={{ root: { backgroundColor: theme.palette.white } }}>
                <AdvGrid rows="[header] 1fr" columns={columnSizes} styles={navGridEl}>
                    <AdvGridItem
                        column={1}
                        row="header"
                        styles={{ root: { display: "flex", alignItems: "center" } }}
                    >
                        <AdvStack horizontal verticalAlign="center" grow>
                            <AdvStackItem shrink={0}>
                                <IconButton
                                    iconProps={navToggleIcon}
                                    styles={navIconButtonStyles}
                                    onClick={onToggleNav}
                                />
                            </AdvStackItem>
                            {pageLayout > EPageComponentSizeType.Mobile && (
                                <AdvStackItem grow>{titleText}</AdvStackItem>
                            )}
                        </AdvStack>
                    </AdvGridItem>
                    <AdvGridItem
                        column={2}
                        row="header"
                        styles={{ root: { display: "flex", alignItems: "center" } }}
                    >
                        {navHistory}
                    </AdvGridItem>
                    <AdvGridItem
                        column={3}
                        row="header"
                        styles={{
                            root: { display: "flex", alignItems: "center" },
                        }}
                    >
                        {pageLayout > EPageComponentSizeType.Mobile && customerDropdown}
                    </AdvGridItem>
                    <AdvGridItem
                        column={4}
                        row="header"
                        styles={{
                            root: { display: "flex", alignItems: "center" },
                        }}
                    >
                        {pageLayout > EPageComponentSizeType.Mobile && webAuftragButton}
                    </AdvGridItem>
                    <AdvGridItem
                        column={5}
                        row="header"
                        styles={{
                            root: { display: "flex", alignItems: "center" },
                        }}
                    >
                        {pageLayout > EPageComponentSizeType.Mobile && mailButton}
                    </AdvGridItem>
                    <AdvGridItem
                        column={6}
                        row="header"
                        styles={{ root: { display: "flex", alignItems: "center" } }}
                    >
                        {persona}
                    </AdvGridItem>
                </AdvGrid>
            </AdvStack>
            <AdvWebOrderPanel
                open={isPanelVisible}
                onOpenChange={(_, { open }) => setPanelVisible(open)}
                close={() => setPanelVisible(false)}
            />
        </>
    );
};
