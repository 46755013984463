export function getRGB(c: string) {
    return parseInt(c, 16);
}

export function getRGBNorm(c: string) {
    return parseInt(c, 16) / 255.0;
}

export function getsRGB(c: string) {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

export function getLuminance(hexColor: string) {
    return (
        0.2126 * getsRGB(hexColor.substring(1, 3)) +
        0.7152 * getsRGB(hexColor.substring(3, 5)) +
        0.0722 * getsRGB(hexColor.substring(5, 7))
    );
}

export function getHashColorFromString(text: string) {
    let h = 0;
    for (let i = 0; i < text.length; i++) {
        h = text.charCodeAt(i) + ((h << 5) - h);
    }
    const colorVal =
        ((h >> 16) & 0xff).toString(16).padStart(2, "0") +
        ((h >> 8) & 0xff).toString(16).padStart(2, "0") +
        (h & 0xff).toString(16).padStart(2, "0");
    return "#" + colorVal;
}

export function colorToHex(color: string) {
    if (color === undefined) {
        console.warn("Fehler bei der Farbumwandlung, eingehender string war undefined.");
        return "#FF00DD"; //Fehlerfarbe, sollte eigentlich nicht passieren
    }
    if (color.startsWith("#")) {
        if (color.length > 7) color.substring(0, 7);
        if (color.length < 7)
            color = "#" + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
        return color;
    } else if (color.toLowerCase().startsWith("rgba(")) {
        const shortClr = color.replaceAll(" ", "");
        const inner = shortClr.substring(5, shortClr.length - 1);
        const colors = inner.split(",");
        return (
            "#" +
            colors
                .map((c, index) => {
                    if (index < 3) return Math.floor(parseFloat(c)).toString(16).padStart(2, "0");
                    // alpha is normalized between [0-1], gets special treatment
                    return Math.floor(parseFloat(c) * 255.0)
                        .toString(16)
                        .padStart(2, "0");
                })
                .join("")
        );
    } else if (color.toLowerCase().startsWith("rgb(")) {
        const shortClr = color.replaceAll(" ", "");
        const inner = shortClr.substring(4, shortClr.length - 1);
        const colors = inner.split(",");
        return (
            "#" +
            colors.map((c) => Math.floor(parseFloat(c)).toString(16).padStart(2, "0")).join("")
        );
    } else {
        switch (color.toLowerCase()) {
            case "red":
                return "#BB0000";
            case "green":
                return "#006600";
            case "blue":
                return "#0000FF";
            case "yellow":
                return "#DDDD00";
            case "gold":
                return "#DDD000";
            case "orange":
                return "#FFB000";
            case "black":
                return "#000000";
            case "white":
                return "#FFFFFF";
            default:
                return "#FFFFFF";
        }
    }
}

export function getContrast(color1: string, color2: string) {
    const c1 = colorToHex(color1);
    const c2 = colorToHex(color2);
    const l1 = getLuminance(c1);
    const l2 = getLuminance(c2);
    return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

export const multiRGBValues = (color: string, multiVal: number) => {
    const c = colorToHex(color);
    return colorToHex(
        "rgba(" +
            (multiVal * getRGBNorm(c.substring(1, 3)) * 255.0).toString() +
            ", " +
            (multiVal * getRGBNorm(c.substring(3, 5)) * 255.0).toString() +
            ", " +
            (multiVal * getRGBNorm(c.substring(5, 7)) * 255.0).toString() +
            ", 1" +
            ")",
    );
};

type TRGBColor = { r: number; g: number; b: number };
// https://stackoverflow.com/a/1664186
export const getCompementaryColor = (colorIn: TRGBColor, reducedLuminosity?: boolean) => {
    // Complement
    type THSVColor = { hue: number; value: number; saturation: number };
    const temphsv = RGB2HSV(colorIn);
    temphsv.hue = HueShift(temphsv.hue, 180.0);
    return HSV2RGB(temphsv);

    function RGB2HSV(rgb: TRGBColor): THSVColor {
        const hsv = { hue: 0, saturation: 0, value: 0 } as THSVColor;
        const max = max3(rgb.r, rgb.g, rgb.b);
        const dif = max - min3(rgb.r, rgb.g, rgb.b);
        hsv.saturation = max == 0.0 ? 0 : (100 * dif) / max;
        if (hsv.saturation == 0) hsv.hue = 0;
        else if (rgb.r == max) hsv.hue = (60.0 * (rgb.g - rgb.b)) / dif;
        else if (rgb.g == max) hsv.hue = 120.0 + (60.0 * (rgb.b - rgb.r)) / dif;
        else if (rgb.b == max) hsv.hue = 240.0 + (60.0 * (rgb.r - rgb.g)) / dif;
        if (hsv.hue < 0.0) hsv.hue += 360.0;
        hsv.value = Math.round((max * 100) / 255);
        if (reducedLuminosity === true && hsv.value >= 75) hsv.value = 60;
        hsv.hue = Math.round(hsv.hue);
        hsv.saturation = Math.round(hsv.saturation);
        return hsv;
    }

    // RGB2HSV and HSV2RGB are based on Color Match Remix [http://color.twysted.net/]
    // which is based on or copied from ColorMatch 5K [http://colormatch.dk/]
    function HSV2RGB(hsv: THSVColor): TRGBColor {
        const rgb = { r: 0, g: 0, b: 0 } as TRGBColor;
        if (hsv.saturation == 0) {
            rgb.r = rgb.g = rgb.b = Math.round(hsv.value * 2.55);
        } else {
            hsv.hue /= 60;
            hsv.saturation /= 100;
            hsv.value /= 100;
            const i = Math.floor(hsv.hue);
            const f = hsv.hue - i;
            const p = hsv.value * (1 - hsv.saturation);
            const q = hsv.value * (1 - hsv.saturation * f);
            const t = hsv.value * (1 - hsv.saturation * (1 - f));
            switch (i) {
                case 0:
                    rgb.r = hsv.value;
                    rgb.g = t;
                    rgb.b = p;
                    break;
                case 1:
                    rgb.r = q;
                    rgb.g = hsv.value;
                    rgb.b = p;
                    break;
                case 2:
                    rgb.r = p;
                    rgb.g = hsv.value;
                    rgb.b = t;
                    break;
                case 3:
                    rgb.r = p;
                    rgb.g = q;
                    rgb.b = hsv.value;
                    break;
                case 4:
                    rgb.r = t;
                    rgb.g = p;
                    rgb.b = hsv.value;
                    break;
                default:
                    rgb.r = hsv.value;
                    rgb.g = p;
                    rgb.b = q;
            }
            rgb.r = Math.round(rgb.r * 255);
            rgb.g = Math.round(rgb.g * 255);
            rgb.b = Math.round(rgb.b * 255);
        }
        return rgb;
    }

    //Adding HueShift via Jacob (see comments)
    function HueShift(h: number, s: number) {
        h += s;
        while (h >= 360.0) h -= 360.0;
        while (h < 0.0) h += 360.0;
        return h;
    }

    //min max via Hairgami_Master (see comments)
    function min3(a: number, b: number, c: number) {
        return a < b ? (a < c ? a : c) : b < c ? b : c;
    }
    function max3(a: number, b: number, c: number) {
        return a > b ? (a > c ? a : c) : b > c ? b : c;
    }
};
