/**
 * Global properties passed by _app to the pages
 */
export enum EPageComponentSizeType {
    SmallMobile = 0,
    Mobile,
    Tablet,
    Desktop,
    DesktopWide,
}

export type TPageComponentProps = {
    size: { width: number; height: number };
    sizeType: EPageComponentSizeType;
};

export const isMobile = (sizeType: EPageComponentSizeType): boolean => {
    return sizeType <= EPageComponentSizeType.Mobile;
};
