import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import { IStackProps, IStackStyles, IStackTokens } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { nestingBackgroundColors } from "@themes/nesting";
import { EAdvValueDataTypes } from "@utils/data-types";
import { mergeObjects } from "@utils/styling";
import React, { ReactNode, useMemo } from "react";

import { deepCompareJSXProps } from "@utils/deep-compare";

export type TAdvStackTokens = IStackTokens; /* do not change */
export type TAdvStackStyles = IStackStyles; /* do not change */

export type TAdvStackProps = Pick<
    IStackProps,
    "horizontal" | "wrap" | "verticalFill" | "horizontalAlign" | "verticalAlign"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        children?: ReactNode;

        horizontalBindingParams?: TAdvValueBindingParams;
        wrapBindingParams?: TAdvValueBindingParams;
        verticalFillBindingParams?: TAdvValueBindingParams;
        horizontalAlignBindingParams?: TAdvValueBindingParams;
        verticalAlignBindingParams?: TAdvValueBindingParams;

        childrenGap?: number;
        childrenGapBindingParams?: TAdvValueBindingParams;
        pageProps?: TPageComponentProps;
    };

const AdvStackImplComp = ({
    advhide,
    designerData,
    horizontal,
    designerProps,
    childrenGap = 4,
    horizontalAlign,
    wrap,
    verticalFill,
    verticalAlign,
    pageProps,
    ...props
}: TAdvStackProps) => {
    useAdvComponent(AdvStackImplComp, props);

    const theme = useAdvTheme();
    const pageSizeType = pageProps?.sizeType ?? EPageComponentSizeType.DesktopWide;

    const style = useMemo(() => {
        let styles = {};
        if (designerData) {
            if (designerData.renderAsDesigner)
                styles = mergeObjects(styles, {
                    backgroundColor:
                        nestingBackgroundColors(theme)[designerData?.nestingDepth ?? 0],
                });
            /*styles = mergeObjects(
                styles,
                horizontal != undefined && horizontal
                    ? { root: { width: "100%" } }
                    : { root: { height: "100%" } },
            );*/
        }

        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false)) {
            const r = getSelectedComponentStyle(theme, true) as object;
            styles = mergeObjects(styles, {
                ...r,
            });
        }
        if (designerData?.renderAsDesigner ?? false) {
            const r = getDesignerModeComponentStyle(theme) as object;
            styles = mergeObjects(styles, { ...r });
        }
        return styles;
    }, [designerData, theme]);

    const childrenGapStr = useMemo(() => {
        if (childrenGap != undefined) {
            return childrenGap.toString() + "px";
        }
        return undefined;
    }, [childrenGap]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <Flex
            //{...props}
            {...designerProps}
            flexDirection={horizontal === true ? "row" : "column"}
            gap={childrenGapStr}
            justifyContent={horizontal === true ? horizontalAlign : verticalAlign}
            wrap={wrap}
            style={{
                ...style,
                height: verticalFill === true ? "100%" : undefined,
                flexGrow: 1,
                width: isMobile(pageSizeType) ? "100%" : horizontal === true ? "100%" : undefined,
            }}
        >
            {props.children}
        </Flex>
    );
};
const AdvStackImpl = React.memo(AdvStackImplComp, deepCompareJSXProps);

const AdvStackSimple = (props: TAdvStackProps) => {
    return <AdvStackImpl {...props}></AdvStackImpl>;
};

const AdvStackComplex = ({
    horizontalBindingParams,
    wrapBindingParams,
    verticalFillBindingParams,
    horizontalAlignBindingParams,
    verticalAlignBindingParams,
    advhide,
    advhideBindingParams,
    horizontal,
    wrap,
    verticalFill,
    horizontalAlign,
    verticalAlign,
    childrenGap,
    childrenGapBindingParams,
    dataArrayIndex = 0,
    ...props
}: TAdvStackProps) => {
    const [isHorizontalValue] = useAdvValueBinderNoDataType(
        horizontalBindingParams,
        horizontal,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isWrapValue] = useAdvValueBinderNoDataType(
        wrapBindingParams,
        wrap,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isVerticalFillValue] = useAdvValueBinderNoDataType(
        verticalFillBindingParams,
        verticalFill,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [horizontalAlignValue] = useAdvValueBinderNoDataType(
        horizontalAlignBindingParams,
        horizontalAlign,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [verticalAlignValue] = useAdvValueBinderNoDataType(
        verticalAlignBindingParams,
        verticalAlign,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [childrenGapValue] = useAdvValueBinderNoDataType(
        childrenGapBindingParams,
        childrenGap,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    return (
        <AdvStackImpl
            {...props}
            horizontal={isHorizontalValue}
            wrap={isWrapValue}
            verticalFill={isVerticalFillValue}
            horizontalAlign={horizontalAlignValue}
            verticalAlign={verticalAlignValue}
            advhide={shouldHide}
            horizontalBindingParams={horizontalBindingParams}
            wrapBindingParams={wrapBindingParams}
            verticalFillBindingParams={verticalFillBindingParams}
            horizontalAlignBindingParams={horizontalAlignBindingParams}
            verticalAlignBindingParams={verticalAlignBindingParams}
            advhideBindingParams={advhideBindingParams}
            childrenGapBindingParams={childrenGapBindingParams}
            dataArrayIndex={dataArrayIndex}
            childrenGap={childrenGapValue}
        ></AdvStackImpl>
    );
};

/**
 * @summary Wrapper für ``Stack``
 * @important Standardmäßig werden die Elemente UNTEREINANDER angeordnet. Kann mit ``horizontal``-Property geändert werden.
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/stack
 */
export const AdvStackComp = ({
    horizontalBindingParams,
    wrapBindingParams,
    verticalFillBindingParams,
    horizontalAlignBindingParams,
    verticalAlignBindingParams,
    advhideBindingParams,
    childrenGapBindingParams,
    ...props
}: TAdvStackProps) => {
    if (
        IsValueBindingTrivial(horizontalBindingParams) &&
        IsValueBindingTrivial(wrapBindingParams) &&
        IsValueBindingTrivial(verticalFillBindingParams) &&
        IsValueBindingTrivial(horizontalAlignBindingParams) &&
        IsValueBindingTrivial(verticalAlignBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(childrenGapBindingParams)
    )
        return (
            <AdvStackSimple
                {...props}
                horizontalBindingParams={horizontalBindingParams}
                wrapBindingParams={wrapBindingParams}
                verticalFillBindingParams={verticalFillBindingParams}
                horizontalAlignBindingParams={horizontalAlignBindingParams}
                verticalAlignBindingParams={verticalAlignBindingParams}
                advhideBindingParams={advhideBindingParams}
                childrenGapBindingParams={childrenGapBindingParams}
            ></AdvStackSimple>
        );
    else
        return (
            <AdvStackComplex
                {...props}
                horizontalBindingParams={horizontalBindingParams}
                wrapBindingParams={wrapBindingParams}
                verticalFillBindingParams={verticalFillBindingParams}
                horizontalAlignBindingParams={horizontalAlignBindingParams}
                verticalAlignBindingParams={verticalAlignBindingParams}
                advhideBindingParams={advhideBindingParams}
                childrenGapBindingParams={childrenGapBindingParams}
            ></AdvStackComplex>
        );
};

const AdvStack = React.memo(AdvStackComp, deepCompareJSXProps);
export default AdvStack;

import { TAdvCommonProperties } from "@components/other/common-properties";
import { EPageComponentSizeType, TPageComponentProps, isMobile } from "@components/page-component";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import Flex from "@react-css/flex";
import "./designable";
